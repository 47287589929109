<app-spinner [isLoading]="showLoader"></app-spinner>

<p-panel toggler="header" styleClass="panelHeader" [toggleable]="true" 
  [pTooltip]="!selectedSpecification ? 'To see evaluation details click on `Evaluate specification` button in Specifications table above' : ''" tooltipPosition="top">
  <ng-template pTemplate="header" >
      <p *ngIf="!selectedSpecification" class="boldText">Evaluation details</p>
      <p *ngIf="selectedSpecification" class="boldText">Evaluation details for {{selectedSpecification.specification.polarionId}}: {{selectedSpecification.specification.title}}</p>
  </ng-template>

  <app-evaluation-statistics *ngIf="selectedSpecification && !showLoader" [specificationEvaluation]="selectedSpecification"></app-evaluation-statistics>
 
  <div *ngIf="selectedSpecification" class='requirementsTable' style="padding: 50px 50px 10px;">
    <h5>Requirements linked to specification</h5>
    <p-table #datatable
             [value]="selectedSpecification.requirements"
             dataKey="polarionId"
             scrollDirection="horizontal"
             responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th style="text-align: start">Requirement title</th>
          <th style="width: 10%">IRMA version</th>
          <th style="width: 10%">Asil</th>
          <th style="width: 10%">Test Analysis connected</th>
          <th style="width: 10%">
            Evaluation status
            <i class="pi pi-question-circle" style="font-size: initial"
              [pTooltip]="evaluationRuleMessage" tooltipPosition="bottom"></i>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>
            <p-dropdown [options]="filterOptions" (onChange)="datatable.filter($event.value, 'testAnalysis.length', 'customTableFilter')"
                        styleClass="p-column-filter" placeholder="Any" [showClear]="true">
              <ng-template let-option pTemplate="item">
                <i [class]="option.value === 1
                            ? 'evaluationPassed pi pi-check-circle'
                            : 'evaluationFailed pi pi-exclamation-circle' "
                  style="font-size: initial"></i>
                <span>
                  {{option.label}}
                </span>
              </ng-template>
            </p-dropdown>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-requirementEvaluation let-expanded="expanded">
        <tr>
          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="requirementEvaluation"
              class="p-button-text p-button-rounded p-button-plain" >
              <i [class]="expanded
                            ? 'pi pi-chevron-down'
                            : 'pi pi-chevron-right'"
                 style="font-size: initial"></i>
            </button>
          </td>
          <td style="text-align: start">{{ requirementEvaluation.title }}</td>
          <td>{{ requirementEvaluation.irmaVersion }}</td>
          <td>{{ requirementEvaluation.asil }}</td>
          <td>
            <i [class]="requirementEvaluation.testAnalysis.length > 0
                ? 'evaluationPassed pi pi-check-circle'
                : 'evaluationFailed pi pi-exclamation-circle' "
               [pTooltip]="requirementEvaluation.testAnalysis.length > 0
                ? 'Test Analysis connected'
                : 'Test Analysis not connected'"
               style="font-size: initial"></i>
          </td>
          <td>
            <i [class]="requirementEvaluation.evaluationPassed
                ? 'evaluationPassed pi pi-check-circle'
                : 'evaluationFailed pi pi-exclamation-circle' "
               [pTooltip]="requirementEvaluation.evaluationPassed
                ? 'Evaluation passed'
                : 'Evaluation failed'"
               style="font-size: initial"></i>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-requirementEvaluation>
        <tr>
          <td colspan="4">
            <div style="margin: 4rem 15rem;">
              <p-table [value]="requirementEvaluation.testAnalysis"
                       dataKey="testAnalyze.uid"
                       scrollDirection="horizontal"
                       responsiveLayout="scroll">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="text-align: start">Test Analyze</th>
                    <th style="width: 10%">Version</th>
                    <th style="width: 20%">Approval status</th>
                    <th style="width: 20%">Test level</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-testAnalyze>
                  <tr>
                    <td style="text-align: start">{{ testAnalyze.name }}</td>
                    <td style="width: 10%">{{ testAnalyze.version }}</td>
                    <td style="width: 20%">
                      <a class="sdds-link"
                         href="http://review.tms.test.scania.com/#/approvals/item/{{testAnalyze.uid}}/{{ testAnalyze.version }}"
                         target="_blank">
                        {{ testAnalyze.approvalStatus === 4
                                  ? "Approved"
                                  : "Not approved" }}
                      </a>
                    </td>
                    <td style="width: 20%">{{ testAnalyze.approvalLevel }}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6">
                      There are no Test Analysis for this requirement.
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">
            There are no requirements for this specification.
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</p-panel>


