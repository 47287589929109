import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { TestItemActionButton } from 'src/app/components/shared/test-item-action/test-item-action.component';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { OrganizationalGroup } from 'src/app/models/dto/organizationalGroup';
import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestAnalysisDto } from 'src/app/models/dto/testAnalysisDto';
import { TestLevel } from 'src/app/models/dto/testLevel';
import { formField } from 'src/app/models/formField';
import { PreviewChange } from 'src/app/models/previewChange';
import { Sop } from 'src/app/models/sop';
import { ItemsService } from 'src/app/services/api/items.service';
import { TestAnalysisApiService } from 'src/app/services/api/test-analysis-api.service';
import { ButtonService } from 'src/app/services/button.service';
import { ChangesService } from 'src/app/services/changes.service';
import { createFormGroup, disableAllFields, enableAllFields } from 'src/app/services/helpers/formHelper';
import { TestAnalysisActions } from 'src/app/store/testAnalysis/testAnalysis.actions';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { SopActions } from 'src/app/store/sop/sop.actions';
import { SopState } from 'src/app/store/sop/sop.state';
import { TestCasesActions } from 'src/app/store/testCases/testCases.actions';
import { TestCasesState } from 'src/app/store/testCases/testCases.state';
import { TestLevelsActions } from 'src/app/store/testLevels/testLevels.actions';
import { TestLevelsState } from 'src/app/store/testLevels/testLevels.state';
import { FormFieldOption } from 'src/app/models/formFieldOption';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionService } from 'src/app/services/permission.service';
import { environment } from 'src/environments/environment';
import { ItemFactory } from 'src/app/services/item-factory/item.factory';
import { ItemType } from 'src/app/models/enums/itemType';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { ReviewService } from 'src/app/services/review/review.service';
import { Approval } from 'src/app/models/review/approval';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';
import { FunctionalGroupsActions } from 'src/app/store/functionalGroups/functionalGroups.actions';
import { FunctionalGroup } from 'src/app/models/dto/functionalGroup';
import { FunctionalGroupsState } from 'src/app/store/functionalGroups/functionalGroups.state';

@Component({
  selector: 'test-analysis',
  templateUrl: './test-analysis.component.html',
  styleUrls: ['./test-analysis.component.scss'],
  providers: [MessageService]
})
export class TestAnalysisComponent implements OnInit {
  @Input() testAnalysisUid: string = '';

  fields: formField[];
  testItem: TestAnalysisDto;
  loggedInUsername: string;
  loggedInGroup: OrganizationalGroup;
  company: string;
  addPageActive: boolean = false;
  form: FormGroup;

  hasChange: boolean = false;
  changes: PreviewChange[] = [];
  showPreviewChanges: boolean = false;
  lockedItem: boolean;

  subject = new Subject<String>();

  linkedRequirements: RequirementDto[];
  linkedSpecifications: SpecificationDto[];
  testCases: FormFieldOption[];
  sopValues: Sop[];
  private subscriptions: Subscription[] = [];
  currentUserSubscription: Subscription;
  valueSubscription: Subscription;
  sopSubscription: Subscription;
  formInitialValue: string;

  requirementsLoadedToForm: boolean = false;
  specificationsLoadedToForm: boolean = false;

  informationFields: formField[];
  requirementInfoSpecificationField: formField;
  requirementInfoRequirementField: formField;
  riskLevelAttributeFields: formField[];
  optionFields: formField[];
  testCaseLinkField: formField;
  testCaseLinksLoadedToForm: boolean;
  actionButtons: TestItemActionButton;

  checkOutBtnToolTip: string;
  hideForm: boolean = false;
  isHero = environment.IsHero;

  readonly ReviewCommentGroup = ReviewCommentGroup;
  reviewVisable$ = this.reviewService.reviewVisable$

  approval: Approval

  itemType = ItemType.TestAnalysis

  constructor(private testAnalysisApiService: TestAnalysisApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    public checkOutItemService: CheckedOutItemService,
    private store: Store,
    private changesService: ChangesService,
    private messageService: MessageService,
    private buttonService: ButtonService,
    private permissionService: PermissionService,
    private reviewService: ReviewService) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let itemUidRouteParameter = params.get("testitemuid");
      this.actionButtons = this.buttonService.setDefaultInit()
      this.addPageActive = itemUidRouteParameter === RouteSegments.ADD;
      if (this.addPageActive) {
        this.actionButtons = this.buttonService.setAddButtons()
        this.hasChange = true;
        this.testAnalysisUid = '';
        this.loadData();
      }
      else {
        this.testAnalysisUid = itemUidRouteParameter;
        this.testAnalysisApiService.getByUid(this.testAnalysisUid).subscribe(response => { 
          if (!response.success) {
            this.hideForm = true;
            this.clearValidationErrors();
            if(response.responseMessages?.length > 0)
              this.messageService.add({ severity: 'error', summary: response.responseMessages[0], sticky: true});
          }
          else
          {
            this.testItem = response.returnValue;//as TestAnalysisDto;
            this.loadData();
            if (this.testItem) {
              this.loadReviewData()
            }
          }
        })
      }
    })
  }

  loadData(){
    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.loggedInUsername = result.userName;
      this.company = result.companyName;
    });
    this.setButtonState();
    this.testAnalysisApiService.getUiTemplate().subscribe(result => {
      this.fields = result.sort(x => x.order);
      this.loadFields();
      this.form = createFormGroup(this.fields, ItemType.TestAnalysis);
      this.populateDropdownValues().subscribe(x => {
        if(this.testItem) {
          this.populatePropertiesToFormGroupFromDto();
          this.populateLinksToFormGroupFromDto()
          this.setControlState();
          this.formInitialValue = JSON.stringify(this.form.value)
          
          //if item is locked start tracking changes
          if (this.testItem.lockedBy == this.loggedInUsername) 
            this.onChanges();
        }
      });
    });
  }

  loadReviewData() {
      // todo: this has to be changed, it may trigger race condition
      // this.reviewService.getApprovalObservable()
      this.approval = this.reviewService.approvals.filter( x => x.itemUid === this.testItem.uid)[0];
      this.reviewService.loadComments(this.testItem.uid, this.testItem.version)
  }
  
  setButtonState(){
    this.buttonState()
    //subscribe to change of checked out item
    let subscription = this.checkOutItemService.checkedItemUidChange.subscribe(itemUid => this.buttonState());
    this.subscriptions.push(subscription);
  }

  buttonState(){ 
    this.checkOutBtnToolTip = '';
    if(this.addPageActive)
      return;
    if(this.checkOutItemService.exists() && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'You have another item checked out. Check in or cancel that item first';
    }
    
    if(/*this.checkOutItemService.exists() && */this.testItem.lockedBy == this.loggedInUsername)//Issue when checked out item and f5
      this.actionButtons = this.buttonService.setCheckedOutButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy != '' && this.testItem.lockedBy != this.loggedInUsername){
      this.actionButtons = this.buttonService.setReadonlyButtons();
      this.checkOutBtnToolTip = 'This item is already checked out by another user';
    }
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy == '')
      this.actionButtons = this.buttonService.setEditButtons();

    this.permissionService.checkPermission(this.loggedInUsername, this.testItem.alternative.ownerIds, this.testItem.alternative.testedByGroupIds).subscribe(hasPermission => {
      if(!hasPermission) {
        this.actionButtons = this.buttonService.setReadonlyButtons();
        this.checkOutBtnToolTip = 'You do not have permission to edit this item';
      }
    })

    // don't show parent items for test analysis
    //2024-10-22 Maybe now we should with TSpec
    this.actionButtons.showViewParentItems = false;
  }

  setControlState(){
    if(this.actionButtons.stateReadOnly || this.actionButtons.stateEdit)
      disableAllFields(this.fields, this.form);
    
    if(this.actionButtons.stateAdd || this.actionButtons.stateCheckedOut)
      enableAllFields(this.fields, this.form);
    
  }

  populatePropertiesToFormGroupFromDto(){
    for (var property in this.testItem) {
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if (matchingControl && matchingControl != formControlConstants.Description && this.testItem[property]){
        if(matchingControl == formControlConstants.ImpactIfFailureAppears || matchingControl == formControlConstants.ProbabilityOfFailure || matchingControl == formControlConstants.TotalRiskLevel){
          this.form.get(matchingControl).setValue({key: this.testItem[property], value: this.testItem[property]});
        } else  
          this.form.get(matchingControl).setValue(this.testItem[property]);
      } 
    }

    for (var property in this.testItem.alternative){
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if(matchingControl && this.testItem.alternative[property]){
        if (matchingControl == formControlConstants.SOPStart || matchingControl == formControlConstants.SOPEnd){
          let sopValue = this.sopValues.filter(sop => sop.name == this.testItem.alternative[property]).map(x => { return {key: x.name, value: x.name}})
          this.form.get(matchingControl).setValue(sopValue[0])//can only be one
        } else
          this.form.get(matchingControl).setValue(this.testItem.alternative[property])
      }
    }
  }

  populateLinksToFormGroupFromDto(){
    var values = [];
    this.fields.forEach(field => {
      switch (field.name){
        case formControlConstants.TAOwner: {
          this.testItem.alternative.ownerIds?.forEach(ownerId => {
            var match = field.options?.find(value => value.key == ownerId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TATestedByOrganizationalGroup: {
          this.testItem.alternative.testedByGroupIds?.forEach(groupId => {
            var match = field.options?.find(value => value.key == groupId.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TATestCase: {
          this.store.select<BaseTestItemDto[]>(TestCasesState.getAllTestCases).subscribe(testCases => {
            if(!testCases || testCases.length == 0)
               return;
            this.testItem.alternative.testCases?.forEach(testCase => {
              let completeItem = this.updateValuesOfItem(testCase, testCases);
              values.push(completeItem);
            });
            this.form.controls[field.name].patchValue(values);
            this.testCaseLinksLoadedToForm = true;
          });
          break;
        }
        case formControlConstants.TATestLevels: {
          var match = field.options?.find(value => {
            const testLevelIds = this.testItem.alternative?.testLevelIds;
            return testLevelIds && testLevelIds.length > 0 && value.key == testLevelIds[0]?.toLowerCase();
        });
          this.form.controls[field.name].patchValue(match)
          break;
        }
        case formControlConstants.TADelegatedToTestLevels: {
          this.testItem.alternative.excludedFromTest?.delegatedToTestLevelIds?.forEach(testLevel => {
            var match = field.options?.find(value => value.key == testLevel.toLowerCase())
            values.push(match)
          })
          this.form.controls[field.name].patchValue(values)
          break;
        }
        case formControlConstants.TARequirement: {
          this.testItem.requirements?.forEach(requirement => {
            values.push(requirement)
          })
          this.form.controls[field.name].patchValue(values)
          this.requirementsLoadedToForm = true;
          break;
        }
        case formControlConstants.TASpecification: {
          this.testItem.alternative.specifications?.forEach(specification => {
            if(specification.linkedRequirement)
              specification.linkedRequirement.title = specification.linkedRequirement?.title ?? ''
            values.push(specification)
          })
          this.form.controls[field.name].patchValue(values)
          this.specificationsLoadedToForm = true
          break;
        }
        // case formControlConstants.TATestedByFunctionalGroup: {//fg
        //   this.testItem.alternative.testedByGroupIds?.forEach(functionalGroupId => {
        //     var match = field.options?.find(value => value.key == functionalGroupId.toLowerCase())
        //     values.push(match)
        //   })
        //   this.form.controls[field.name].patchValue(values)
        //   break;
        // }
        // case formControlConstants.TAFunctionalGroup: {
        //   this.testItem.alternative.ownerIds?.forEach(functionalGroupId => {
        //     var match = field.options?.find(value => value.key == functionalGroupId.toLowerCase())
        //     values.push(match)
        //   })
        //   this.form.controls[field.name].patchValue(values)
        //   break;
        // }
      }
      values = [];
      })
    }

    updateValuesOfItem(itemLink: BaseTestItemDto, allItemLinks: BaseTestItemDto[]) {
      let testCaseInfo = allItemLinks.find(x => x.uid == itemLink.uid);
      let fullTestCase: BaseTestItemDto = {...testCaseInfo, approvalStatus: '', approvedForTestLevel: '', comment: '', modifiedBy: '' }
      return itemLink = fullTestCase;
    }

  populateDropdownValues() {
    this.store.dispatch(new OrganizationalGroupsActions.SetAllOrganizationalGroups());
    // this.store.dispatch(new FunctionalGroupsActions.SetAllFunctionalGroups());//fg
    this.store.dispatch(new TestCasesActions.SetAllTestCases(this.company));
    this.store.dispatch(new SopActions.SetAllSop());
    this.store.dispatch(new TestLevelsActions.SetAllTestLevels());

    let organizationalGroupsObservable = this.store.select<OrganizationalGroup[]>(OrganizationalGroupsState.getAllOrganizationalGroups);
    // let functionalGroupsObservable = this.store.select<FunctionalGroup[]>(FunctionalGroupsState.getAllFunctionalGroups);
    let sopObservable = this.store.select<Sop[]>(SopState.getAllSop);
    let testLevelsObservable = this.store.select<TestLevel[]>(TestLevelsState.getAllTestLevels);
    let testCaseObservable = this.store.select<BaseTestItemDto[]>(TestCasesState.getAllTestCases);
    
    const subscription = combineLatest([organizationalGroupsObservable, /*functionalGroupsObservable, */sopObservable, testLevelsObservable, testCaseObservable])
    .subscribe(([organizationalGroups, /*functionalGroups,*/ sopValues, testLevels, testCases]) =>{
      if (!organizationalGroups || organizationalGroups.length == 0 /*|| !functionalGroups || functionalGroups.length == 0*/ || !sopValues
          || !testLevels || testLevels.length == 0 || !testCases ) 
        return;

      // if(!environment.IsHero && sopValues.length == 0)
      //   return;

      if(!environment.IsHero && !environment.IsProduction && testCases.length == 0)
        return;

      var ownerFields = this.fields.filter(field => field.name === formControlConstants.TAOwner || field.name === formControlConstants.TATestedByOrganizationalGroup)
      if(ownerFields)
        ownerFields.forEach(ownerField => {
          ownerField.options = organizationalGroups.map(x => { return {key: x.uid, value: x.name}});;
        })
      
      // var functionalGroupFields = this.fields.filter(field => field.name === formControlConstants.TAFunctionalGroup || field.name === formControlConstants.TATestedByFunctionalGroup)
      // if(functionalGroupFields)
      //   functionalGroupFields.forEach(functionalGroupField => {
      //     functionalGroupField.options = functionalGroups.map(x => { return {key: x.uid, value: x.name}});;
      //   })

      var sopFields = this.fields.filter(field => field.name === formControlConstants.SOPEnd || field.name === formControlConstants.SOPStart)
      if(sopFields){
        sopFields.forEach(sopField => {
          sopField.options = sopValues.map(x => { return {key: x.name, value: x.name}});
        })
        this.sopValues = sopValues;
      }
      var testLevelFields = this.fields.filter(field => field.name === formControlConstants.TATestLevels || field.name === formControlConstants.TADelegatedToTestLevels)
      if(testLevelFields)
      testLevelFields.forEach(testLevelField => {
        testLevelField.options = testLevels.map(x => { return {key: x.id.toString(), value: x.name}});;
      })

      setTimeout(() => {
        this.subject.next("done");
      },5);  
    })
    this.subscriptions.push(subscription);
    return this.subject.asObservable();
  }

  checkOut(): void {
    if (!this.testAnalysisUid)
      return;

    this.itemsService.checkout(this.testAnalysisUid).subscribe(result => {
      if (result === false) {
        this.messageService.add({ severity: 'error', summary: 'Item check out failed', sticky: false});
        return;
      }

      this.actionButtons = this.buttonService.setCheckedOutButtons()
      enableAllFields(this.fields, this.form);
        
      this.testItem.lockedBy = this.loggedInUsername;
      this.testItem.lockedTime = new Date();   

      this.formInitialValue = JSON.stringify(this.form.value);
      this.onChanges();
      this.checkOutItemService.set(this.testItem?.uid, ItemType.TestAnalysis);
      this.store.dispatch(new TestAnalysisActions.SetUpdateNeeded(true));
      this.hasChange = false;
    });
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(formValue => {
      this.hasChange =  this.formInitialValue !== JSON.stringify(this.form.value);
      
      let currentChanges = this.changesService.getDifferences(
        JSON.parse(this.formInitialValue),
        formValue,
        this.fields,
      );
      this.changes = currentChanges;
    });
  }

  unlockItem(): void {    
    this.clearValidationErrors();

    if (this.addPageActive || this.testItem.lockedBy == '') {
      this.resetChanges();
      return;
    } 

    this.itemsService.unlock(this.testItem.uid).subscribe(() => {
      this.actionButtons = this.buttonService.setEditButtons()
      this.testItem.lockedBy = '';
      this.testItem.lockedTime = null;
      disableAllFields(this.fields, this.form);
      this.resetChanges();
      
      this.checkOutItemService.set();
      this.store.dispatch(new TestAnalysisActions.SetUpdateNeeded(true));
    });
  }

  onSubmit(): void {
    if(!this.validateForSubmit())
      return;

    let requestDto = ItemFactory.createItem(this.testItem, this.form, ItemType.TestAnalysis) as TestAnalysisDto;

    if(this.addPageActive){
      this.testAnalysisApiService.create(requestDto).subscribe(response => {
        if(response.success)
          this.navigateToList();
        else
          this.showValidationErrors(response.responseMessages);
      });
    } else {
      this.testAnalysisApiService.update(requestDto).subscribe(response => {
        if(response.success) {
          this.unlockItem();
          this.navigateToList();
        } else {
          this.showValidationErrors(response.responseMessages);
        }
      });
    }
  }

  validateForSubmit() {
    this.clearValidationErrors();
  
    if(this.form.invalid){
      this.messageService.add({ severity: 'error', summary: 'Not all required fields are populated', sticky: true});
      return false;
    }
  
    if(!this.addPageActive && !this.hasChange) {
      this.messageService.add({ severity: 'error', summary: 'You made no changes', sticky: true});
      return false;
    }
  
    return true;
  }

  showValidationErrors(errors: string[]) {
    this.clearValidationErrors();
    this.messageService.add({ severity: 'error', summary: 'Item not saved!', sticky: true});

    errors.forEach(errorMessage => {
      this.messageService.add({ severity: 'error', summary: errorMessage, sticky: true });
    });
  }

  clearValidationErrors() {
    this.messageService.clear();
  }

  private navigateToList(): void {
    window.location.href = `/#/${RouteSegments.TEST_ANALYSIS}`
    window.location.reload();
  }

  resetChanges() {
    this.form.reset(this.formInitialValue ? JSON.parse(this.formInitialValue) : {});
    this.hasChange = false;
    this.changes = [];
  }
  
  loadFields(){
    this.generalInformationFields();
    this.requirementFields();
    this.riskLevelFields();
    this.optionsFields();
    this.testCaseLinkField = this.fields.find(x => x.category === "Test Cases");
  }
  
  generalInformationFields(){
    if(this.fields){
      this.informationFields = this.fields.filter(x => x.category === "General information"  && (this.isHero ? x.label !== 'Restricted View Access' : true));
      if(this.isHero){
        [this.informationFields[2], this.informationFields[3]] = [this.informationFields[3], this.informationFields[2]];//change node order?
        this.informationFields[3].label = 'Exploratory Testing Only';
      }
      else{
        [this.informationFields[2], this.informationFields[4]] = [this.informationFields[4], this.informationFields[2]];//change node order?
      this.informationFields[4].label = 'Exploratory Testing Only';
      }
    }
      
    else
      this.informationFields = [];
  }

  requirementFields(){
    if(this.fields){
      let requirementInfoFields = this.fields.filter(x => x.category === "Requirement Info") ;
      this.requirementInfoRequirementField = requirementInfoFields.find(x => x.name === formControlConstants.TARequirement);
      this.requirementInfoSpecificationField = requirementInfoFields.find(x => x.name === formControlConstants.TASpecification);
    }
    else{
      this.requirementInfoRequirementField = null;
      this.requirementInfoSpecificationField = null;
    }
  }

  riskLevelFields(){
    if(this.fields)
      this.riskLevelAttributeFields = this.fields.filter(x => x.category === "Risk level attributes");
    else
      this.riskLevelAttributeFields = [];
  }

  optionsFields(){
    if(this.fields){
      this.optionFields = this.fields.filter(x => x.category === "Options");
      if(this.isHero)
        this.optionFields = this.fields.filter(x => x.category === "Options" && (x.label !== 'SOP Start' && x.label !== 'SOP End'));
    }
    else
      this.optionFields = [];
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) 
        subscription.unsubscribe();
    }
    this.subscriptions = [];
}
}
