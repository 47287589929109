import { gql } from "apollo-angular";

export const GET_APPROVALS_QUERY = gql`query configuratorPagedApprovalList ($ApprovalsContextInput: ApprovalsContextInput) {
    configuratorPagedApprovalList (ApprovalsContextInput: $ApprovalsContextInput) {
        TotalItems
        CurrentPage
        PageSize
        Result {
          ActionBy
          ActionTime
          ApprovalLevel
          ApproverType
          DueDate
          Id
          ItemName
          ItemTypeId
          ItemUid
          ItemVersion
          NumberOfComments
          ParentId
          ParentTypeId
          RegBy
          RegTime
          RequestComment
          Status
          TypeName
          ValidTo
          Reviewers
        }
    }
}
  `

export const GET_APPROVAL_QUERY = gql`query configuratorApprovalById ($Id: Int!) {
    configuratorApprovalById (Id: $Id) {
        ActionBy
        ActionTime
        ApprovalLevel
        ApproverType
        DueDate
        Id
        ItemName
        ItemTypeId
        ItemUid
        ItemVersion
        NumberOfComments
        ParentId
        ParentTypeId
        RegBy
        RegTime
        RequestComment
        Status
        TypeName
        ValidTo
        Reviewers
    }
}
  `

export const GET_APPROVALS_FOR_ITEM_QUERY = gql`query configuratorAllApprovalsByItem ($ItemUid: String!, $Version: Int!) {
  configuratorAllApprovalsByItem (ItemUid: $ItemUid, Version: $Version) {
      ActionBy
      ActionTime
      ApprovalLevel
      ApproverType
      DueDate
      Id
      ItemName
      ItemTypeId
      ItemUid
      ItemVersion
      NumberOfComments
      ParentId
      ParentTypeId
      RegBy
      RegTime
      RequestComment
      Status
      TypeName
      ValidTo
      Reviewers
  }
}
`

export const GET_APPROVAL_COMMENTS_QUERY = gql`query configuratorReviewCommentList ($GetReviewCommentListInput: GetReviewCommentListInput) {
  configuratorReviewCommentList(GetReviewCommentListInput: $GetReviewCommentListInput) {
    Comments {
      Id
      ItemKey
      ItemVersion
      ParentId
      PropertyKey
      PropertyName
      RegBy
      RegTime
      Status
      Text
      ValidTo
      ValidToRegBy
      CommentGroup
    }
  }
}
`

export const GET_USERS_QUERY = gql`query configuratorUsers {
    configuratorUsers {
      Name
      Username
    }
}
`
