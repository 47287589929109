import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { TestItemActionButton } from 'src/app/components/shared/test-item-action/test-item-action.component';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { FunctionalGroup } from 'src/app/models/dto/functionalGroup';
import { OrganizationalGroup } from 'src/app/models/dto/organizationalGroup';
import { TestSpecificationDto } from 'src/app/models/dto/testSpecificationDto';
import { ItemType } from 'src/app/models/enums/itemType';
import { formField } from 'src/app/models/formField';
import { PreviewChange } from 'src/app/models/previewChange';
import { Approval } from 'src/app/models/review/approval';
import { ReviewCommentGroup } from 'src/app/models/review/commentGroup';
import { ItemsService } from 'src/app/services/api/items.service';
import { TestSpecificationApiService } from 'src/app/services/api/test-specification-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonService } from 'src/app/services/button.service';
import { ChangesService } from 'src/app/services/changes.service';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';
import { createFormGroup, disableAllFields, enableAllFields } from 'src/app/services/helpers/formHelper';
import { ItemFactory } from 'src/app/services/item-factory/item.factory';
import { PermissionService } from 'src/app/services/permission.service';
import { ReviewService } from 'src/app/services/review/review.service';
import { FunctionalGroupsActions } from 'src/app/store/functionalGroups/functionalGroups.actions';
import { FunctionalGroupsState } from 'src/app/store/functionalGroups/functionalGroups.state';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { TestAnalysisActions } from 'src/app/store/testAnalysis/testAnalysis.actions';
import { TestAnalysisState } from 'src/app/store/testAnalysis/testAnalysis.state';
import { TestSpecificationsActions } from 'src/app/store/testSpecifications/testSpecifications.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-specification',
  templateUrl: './test-specification.component.html',
  styleUrls: ['./test-specification.component.scss'],
  providers: [MessageService]
})
export class TestSpecificationComponent implements OnInit {

  @Input() testSpecificationUid: string = '';
  
  fields: formField[];
  testItem: TestSpecificationDto;
  loggedInUsername: string;
  company: string;
  addPageActive: boolean = false;
  form: FormGroup;

  hasChange: boolean = false;
  changes: PreviewChange[] = [];
  showPreviewChanges: boolean = false;

  subject = new Subject<String>();

  private subscriptions: Subscription[] = [];
  currentUserSubscription: Subscription;
  valueSubscription: Subscription;
  formInitialValue: string;

  informationFields: formField[];
  testAnalysisLinkField: formField;
  testAnalysisLinksLoadedToForm: boolean;
  actionButtons: TestItemActionButton;
  checkOutBtnToolTip: string;
  hideForm: boolean = false;

  readonly ReviewCommentGroup = ReviewCommentGroup;
  reviewVisable$ = this.reviewService.reviewVisable$

  approval: Approval

  itemType = ItemType.TestSpecification


  constructor(private testSpecificationApiService: TestSpecificationApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private itemsService: ItemsService,
    public checkOutItemService: CheckedOutItemService,
    private store: Store,
    private changesService: ChangesService,
    private messageService: MessageService,
    private buttonService: ButtonService,
    private permissionService: PermissionService,
    private reviewService: ReviewService) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    
    this.route.paramMap.subscribe(params => {
      // reload the item data when the route id changes
      let itemUidRouteParameter = params.get("testitemuid");

      this.actionButtons = this.buttonService.setDefaultInit()

      this.addPageActive = itemUidRouteParameter === RouteSegments.ADD;
      if (this.addPageActive) {
        this.actionButtons = this.buttonService.setAddButtons()
        this.hasChange = true;
        this.testSpecificationUid = '';
        this.loadData();
      }
      else {
        this.testSpecificationUid = itemUidRouteParameter;
        this.testSpecificationApiService.getByUid(this.testSpecificationUid).subscribe(response => { 
          if (!response.success) {
            this.hideForm = true;
            this.clearValidationErrors();
            if(response.responseMessages?.length > 0)
              this.messageService.add({ severity: 'error', summary: response.responseMessages[0], sticky: true});
          }
          else
          {
            this.testItem = response.returnValue;
            this.loadData();
            if (this.testItem) {
              this.loadReviewData()
            }
          }
        })
      }
    });
  }

  loadData(){
    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.loggedInUsername = result.userName;
      this.company = result.companyName;
    });
    this.setButtonState();
    this.testSpecificationApiService.getUiTemplate().subscribe(result => {
      this.fields = result.sort(x => x.order);
      this.loadFields();
      this.form = createFormGroup(this.fields, ItemType.TestSpecification);
      this.populateDropdownValues().subscribe(x => {
        if(this.testItem) {
          this.populatePropertiesToFormGroupFromDto();
          this.populateLinksToFormGroupFromDto()
          this.setControlState();
          this.formInitialValue = JSON.stringify(this.form.value);
            
          //if item is locked start tracking changes
          if (this.testItem.lockedBy == this.loggedInUsername) 
            this.onChanges();
        }
      });
    });
  }

  loadReviewData() {
    // todo: this has to be changed, it may trigger race condition
    this.approval = this.reviewService.approvals.filter( x => x.itemUid === this.testItem.uid)[0];
    this.reviewService.loadComments(this.testItem.uid, this.testItem.version)
  }

  setButtonState(){
    this.buttonState()
    //subscribe to change of checked out item
    let subscription = this.checkOutItemService.checkedItemUidChange.subscribe(itemUid => this.buttonState());
    this.subscriptions.push(subscription);
  }

  buttonState(){
    if(this.addPageActive)
      return;

    if(this.checkOutItemService.exists() && this.testItem.lockedBy != this.loggedInUsername)
      this.actionButtons = this.buttonService.setReadonlyButtons();
    
    if(/*this.checkOutItemService.exists() && */this.testItem.lockedBy == this.loggedInUsername)
      this.actionButtons = this.buttonService.setCheckedOutButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy != '' && this.testItem.lockedBy != this.loggedInUsername)
      this.actionButtons = this.buttonService.setReadonlyButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy == '')
      this.actionButtons = this.buttonService.setEditButtons();
    
    this.permissionService.checkPermission(this.loggedInUsername, this.testItem.ownerIds).subscribe(hasPermission => {
      if(!hasPermission) {
        this.actionButtons = this.buttonService.setReadonlyButtons();
        this.checkOutBtnToolTip = 'You do not have permission to edit this item';
      }
    })
  }

  setControlState(){
    if(this.actionButtons.stateReadOnly || this.actionButtons.stateEdit)
      disableAllFields(this.fields, this.form);

    if(this.actionButtons.stateAdd || this.actionButtons.stateCheckedOut)
      enableAllFields(this.fields, this.form);
  }

  populatePropertiesToFormGroupFromDto(){
    for (var property in this.testItem) {
      const controlName = property.toLowerCase();
      const matchingControl = Object.keys(this.form.controls).find(control => control.toLowerCase() === controlName);
      if (matchingControl && matchingControl != formControlConstants.Description && this.testItem[property]) 
        this.form.get(matchingControl).setValue(this.testItem[property]);
    }
  }

  populateLinksToFormGroupFromDto(){
  var values = [];
  this.fields.forEach(field => {
    switch (field.name){
      case formControlConstants.TSpecOwner: {
        this.testItem.ownerIds?.forEach(ownerId => {
          var match = field.options?.find(value => value.key == ownerId.toLowerCase())
          values.push(match)
        })
        this.form.controls[field.name].patchValue(values)
        break;
      }
      // case formControlConstants.TSpecFunctionalGroup: {//fg
      //   this.testItem.ownerIds?.forEach(functionalGroupId => {
      //     var match = field.options?.find(value => value.key == functionalGroupId.toLowerCase())
      //     values.push(match)
      //   })
      //   this.form.controls[field.name].patchValue(values)
      //   break;
      // }
      case formControlConstants.TSpecTestAnalysis: {
        this.store.select<BaseTestItemDto[]>(TestAnalysisState.getAllTestAnalysis).subscribe(testAnalyses => {
          if(!testAnalyses || testAnalyses.length == 0)
            return;
          this.testItem.testAnalyses?.forEach(testAnalysis => {
            let completeItem = this.updateValuesOfItem(testAnalysis, testAnalyses);
            values.push(completeItem);
          });
          this.form.controls[field.name].patchValue(values);
          this.testAnalysisLinksLoadedToForm = true;
        });
        break;
      }
    }
    values = [];
    })
  }

  updateValuesOfItem1(itemLink: BaseTestItemDto, allItemLinks: BaseTestItemDto[]) {//can this be deleted?
    let testItemInfo = allItemLinks.find(x => x.uid == itemLink.uid);
    itemLink = {...testItemInfo, approvalStatus: '', approvedForTestLevel: '', comment: '', modifiedBy: '' }
  }

  updateValuesOfItem(itemLink: BaseTestItemDto, allItemLinks: BaseTestItemDto[]) {
    let testCaseInfo = allItemLinks.find(x => x.uid == itemLink.uid);
    let fullTestCase: BaseTestItemDto = {...testCaseInfo, approvalStatus: '', approvedForTestLevel: '', comment: '', modifiedBy: '' }
    return itemLink = fullTestCase;
  }

  populateDropdownValues() {
    this.store.dispatch(new OrganizationalGroupsActions.SetAllOrganizationalGroups());
    // this.store.dispatch(new FunctionalGroupsActions.SetAllFunctionalGroups());
    this.store.dispatch(new TestAnalysisActions.SetAllTestAnalysis(this.company));

    let organizationalGroupsObservable = this.store.select<OrganizationalGroup[]>(OrganizationalGroupsState.getAllOrganizationalGroups);
    // let functionalGroupsObservable = this.store.select<FunctionalGroup[]>(FunctionalGroupsState.getAllFunctionalGroups);
    let testAnalysisObservable = this.store.select<BaseTestItemDto[]>(TestAnalysisState.getAllTestAnalysis)

    const subscription = combineLatest([organizationalGroupsObservable, /*functionalGroupsObservable,*/ testAnalysisObservable])
    .subscribe(([organizationalGroups, /*functionalGroups,*/ testAnalyses]) =>{
      if (!organizationalGroups || organizationalGroups.length == 0 /*|| !functionalGroups || functionalGroups.length == 0*/ || !testAnalyses) 
        return;

      if(!environment.IsHero && !environment.IsProduction && testAnalyses.length == 0)
        return;

        var ownerField = this.fields.find(x => x.name === formControlConstants.TSpecOwner);
        if(ownerField)
          ownerField.options = organizationalGroups.map(x => { return { key: x.uid, value: x.name } });

        // var functionalGroupField = this.fields.find(x => x.name === formControlConstants.TSpecFunctionalGroup);//fg
        // if(functionalGroupField)
        //   functionalGroupField.options = functionalGroups.map(x => { return {key: x.uid, value: x.name}});

        setTimeout(() => {
          this.subject.next("done");
        },5);  
    })
    this.subscriptions.push(subscription);
    return this.subject.asObservable();
  }

  checkOut(): void {
    if (!this.testSpecificationUid)
      return;

    this.itemsService.checkout(this.testSpecificationUid).subscribe(result => {
      if (result === false) {
        this.messageService.add({ severity: 'error', summary: 'Item check out failed', sticky: false});
        return;
      }

      this.actionButtons = this.buttonService.setCheckedOutButtons()
      enableAllFields(this.fields, this.form);
        
      this.testItem.lockedBy = this.loggedInUsername;
      this.testItem.lockedTime = new Date();   

      this.formInitialValue = JSON.stringify(this.form.value);
      this.onChanges();
      this.checkOutItemService.set(this.testItem?.uid, ItemType.TestSpecification);
      this.store.dispatch(new TestSpecificationsActions.SetUpdateNeeded(true));
      this.hasChange = false;
    });
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(formValue => {
      this.hasChange =  this.formInitialValue !== JSON.stringify(this.form.value);

      let currentChanges = this.changesService.getDifferences(
        JSON.parse(this.formInitialValue),
        formValue,
        this.fields,
      );
      this.changes = currentChanges;
    });
  }

  unlockItem(): void {   
    this.clearValidationErrors();

    if (this.addPageActive || this.testItem.lockedBy == '') {
      this.resetChanges();
      return;
    } 

    this.itemsService.unlock(this.testItem.uid).subscribe(() => {
      this.actionButtons = this.buttonService.setEditButtons()
      this.testItem.lockedBy = '';
      this.testItem.lockedTime = null;
      disableAllFields(this.fields, this.form);
      this.resetChanges();

      this.checkOutItemService.set();
      this.store.dispatch(new TestSpecificationsActions.SetUpdateNeeded(true));
    });
  }

  onSubmit(): void {
    if(!this.validateForSubmit())
      return;

    let requestDto = ItemFactory.createItem(this.testItem, this.form, ItemType.TestSpecification) as TestSpecificationDto;

    if(this.addPageActive){
      this.testSpecificationApiService.create(requestDto).subscribe(response => {
        if(response.success)
          this.navigateToList();
        else
        this.showValidationErrors(response.responseMessages);
      });
    } else {
      this.testSpecificationApiService.update(requestDto).subscribe(response => {
        if(response.success) {
          this.unlockItem();
          this.navigateToList();
        }
        else {
          this.showValidationErrors(response.responseMessages);
        }
      });
    }
  }

  validateForSubmit() {
    this.clearValidationErrors();
  
    if(this.form.invalid){
      this.messageService.add({ severity: 'error', summary: 'Not all required fields are populated', sticky: true});
      return false;
    }
  
    if(!this.addPageActive && !this.hasChange) {
      this.messageService.add({ severity: 'error', summary: 'You made no changes', sticky: true});
      return false;
    }
  
    return true;
  }

  showValidationErrors(errors: string[]) {
    this.clearValidationErrors();
    this.messageService.add({ severity: 'error', summary: 'Item not saved!', sticky: true});

    errors.forEach(errorMessage => {
      this.messageService.add({ severity: 'error', summary: errorMessage, sticky: true });
    });
  }

  clearValidationErrors() {
    this.messageService.clear();
  }

  private navigateToList(): void {
    window.location.href = `/#/${RouteSegments.TEST_SPECIFICATIONS}`
    window.location.reload();
  }

  resetChanges() {
    this.form.reset(this.formInitialValue ? JSON.parse(this.formInitialValue) : {});
    this.hasChange = false;
    this.changes = [];
  }

  loadFields(){
    this.generalInformationFields();
    this.testAnalysisLinkField = this.fields.find(x => x.category === "Test Analyses");
  }
  
  generalInformationFields(){
    if(this.fields)
      this.informationFields = this.fields.filter(x => x.category === "General information");
    else
      this.informationFields = [];
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) 
        subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
