import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { RequirementMapper } from 'src/app/services/mappers/requirementMapper';
import { SpecificationEvaluation } from 'src/app/models/specificationEvaluation';
import { ApprovalsApiService } from 'src/app/services/api/approvals-api.service';
import { ApprovalDto } from 'src/app/models/dto/approvals/approvalDto';
import { ItemWithApproval } from 'src/app/models/itemWithApproval';
import { TestLevelsActions } from 'src/app/store/testLevels/testLevels.actions';
import { TestLevel } from 'src/app/models/dto/testLevel';
import { TestLevelsState } from 'src/app/store/testLevels/testLevels.state';
import { ApprovalStatus } from 'src/app/models/enums/approvalStatus';
import { MessageService, FilterService} from 'primeng/api';
import { RequirementApiService } from 'src/app/services/api/requirement-api.service';
import { TestAnalysisApiService } from 'src/app/services/api/test-analysis-api.service';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { RequirementEvaluation } from 'src/app/models/requirementEvaluation';

@Component({
  selector: 'app-item-evaluation',
  templateUrl: './item-evaluation.component.html',
  styleUrls: ['./item-evaluation.component.scss'],
  providers: [MessageService]
})
export class ItemEvaluationComponent implements OnInit, OnDestroy {
  @Input() selectedSpecification: SpecificationEvaluation;
  
  evaluatedSpecifications: SpecificationEvaluation[] = [];
  testLevels: TestLevel[] = [];
  showLoader: boolean = false;

  totalNumberOfItemsToEvaluate = 0;

  filterOptions = [{ label: 'Connected', value: 1 }, { label: 'Not connected', value: 0 }];
  evaluationRuleMessage = `Evaluation is passed if:
  - requirement has linked at least one TestAnalysis
  - and all TestAnalyses are approved`;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private testAnalysisApiService: TestAnalysisApiService,
    private requirementApiService: RequirementApiService,
    private approvalsApiService: ApprovalsApiService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.getTestLevels();
    this.registerCustomTableFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    let evaluatedSpecification = this.evaluatedSpecifications.find(x => x.specification?.polarionId == this.selectedSpecification?.specification?.polarionId)
    if(evaluatedSpecification)
      this.selectedSpecification = evaluatedSpecification;
    else
      this.evaluateSpecification();
  }

  getTestLevels() {
    this.store.dispatch(new TestLevelsActions.SetAllTestLevels());

    let subscription = this.store.select<TestLevel[]>(TestLevelsState.getAllTestLevels)
      .subscribe(testLevels => this.testLevels = testLevels);
    this.subscriptions.push(subscription);
  }

  registerCustomTableFilter() {
    this.filterService.register(
      'customTableFilter',
      (value, filter): boolean => {
        if (filter === undefined || filter === null) 
          return true;
        
        if (value === undefined || value === null)
          return false;

        if(value === 0 && filter === 0)
          return true;
        
        if(value > 0 && filter === 1)
          return true;

        return false;
      }
    );
  }

  evaluateSpecification(): void {
    if(!this.selectedSpecification)
      return;

    this.showLoader = true;

    const subscription = this.requirementApiService.getRequirementsForSpecification(this.selectedSpecification.specification.polarionId, this.selectedSpecification.specification.irmaVersion)
      .subscribe((requirementsResult) => {

      this.totalNumberOfItemsToEvaluate = requirementsResult?.length ?? 0;

      let requirements = RequirementMapper.mapEvaluation(requirementsResult);
      this.selectedSpecification.requirements = requirements;

      if(requirements.length == 0)
        this.showLoader = false;

      requirements?.forEach(requirement => this.evaluateRequirement(requirement, requirements.length));
    });
    this.subscriptions.push(subscription);
  }

  private evaluateRequirement(requirement: RequirementEvaluation, numberOfRequirements: number) {
    if(!requirement)
      return;

    this.totalNumberOfItemsToEvaluate--;

    //let asilExists = asilList.find((x) => x.indexOf(requirement.asil) == 0) !== null;
    let subscriptionGetItems = this.testAnalysisApiService.getAllWithLinkedRequirement(requirement.polarionId).subscribe(testAnalyses => {
      this.totalNumberOfItemsToEvaluate += testAnalyses?.length ?? 0;

      if(this.totalNumberOfItemsToEvaluate == 0) {
        this.evaluatedSpecifications.push(this.selectedSpecification);
         this.showLoader = false;         
      }

      testAnalyses?.forEach(testAnalysis => {
        let subscriptionApprovals = this.approvalsApiService.getApprovals(testAnalysis.uid, testAnalysis.version).subscribe((approvals => {
          let itemWithApprovals: ItemWithApproval = this.generateItemWithApproval(testAnalysis, approvals);
          requirement.testAnalysis = requirement.testAnalysis.concat(itemWithApprovals);
  
          var areAllApproved = requirement.testAnalysis.find(x => x.approvalStatus !== ApprovalStatus.Approved) == undefined;
          requirement.evaluationPassed = requirement.testAnalysis.length > 0 && areAllApproved;

          this.totalNumberOfItemsToEvaluate--;

          if(testAnalyses.length == requirement.testAnalysis.length) 
            requirement.testAnalysis.sort((a, b) => (a.approvalStatus > b.approvalStatus ? -1 : 1));

          if(numberOfRequirements == this.selectedSpecification.requirements.length) 
            this.selectedSpecification.requirements.sort((a, b) => (a.title > b.title ? 1 : -1));
          
          if(this.totalNumberOfItemsToEvaluate == 0) { //complete evaluation for selected spec if finished
            this.evaluatedSpecifications.push(this.selectedSpecification);
            this.showLoader = false;         
          }
        }));
      this.subscriptions.push(subscriptionApprovals);
      });
    });
    this.subscriptions.push(subscriptionGetItems);
  }

  private generateItemWithApproval(testAnalysis: BaseTestItemDto, approvals: ApprovalDto[]) {
    var approvalStatus = this.calculateTotalApprovalStatus(approvals);
    var approvalLevel = this.calculateTotalApprovalLevel(approvals);

    let itemWithApproval: ItemWithApproval = {
      approvalStatus: approvalStatus,
      approvalLevel: approvalLevel,
      uid: testAnalysis.uid,
      name: testAnalysis.name,
      version: testAnalysis.version,
    };

    return itemWithApproval;
  }

  private calculateTotalApprovalStatus(approvals: ApprovalDto[]) {
    return approvals.find(x => x.approvalStatus == ApprovalStatus.Approved) ? ApprovalStatus.Approved : ApprovalStatus.ReadyForReview;
  }

  private calculateTotalApprovalLevel(approvals: ApprovalDto[]) {
    var maxLevel = approvals?.sort((a, b) => (a.approvalLevel > b.approvalLevel ? 1 : -1))?.at(0)?.approvalLevel;
    if (!maxLevel)
      return 'Not specified';

    let testLevel = this.testLevels.find(x => x.id === maxLevel)?.name;
    return testLevel ?? 'Not specified';
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      if (subscription && !subscription.closed) 
        subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
