export namespace FunctionalGroupsActions {
    export class SetAllFunctionalGroups {
      static readonly type = '[FunctionalGroups] SetAllFunctionalGroups';
      constructor() {}
    }
  
    export class SetFunctionalGroupsByUsername {
      static readonly type =
        '[FunctionalGroups] SetFunctionalGroupsByUsername';
      constructor(public username: string) {}
    }
  }