import { AfterViewInit, Component,  Input, OnChanges, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Console } from 'console';
import { NgxJoditProComponent } from 'ngx-jodit-pro';
import { formControlConstants } from 'src/app/constants/formControlConstants';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: []
})
export class TextEditorComponent implements OnChanges, AfterViewInit{
  @Input() description?: string;
  @Input() form!: FormGroup;
  @Input() readOnly: boolean;
  @ViewChild('editor', { static: false }) joditComponent ? : NgxJoditProComponent;
  private readonly MAX_IMAGE_SIZE = 1 * 1024 * 1024; //1mb
  private readonly MAX_WIDTH = 300; 
  private readonly MAX_HEIGHT = 300;
  private readonly MIN_WIDTH = 100; 
  private readonly MIN_HEIGHT = 100;

  constructor(private sanitizer: DomSanitizer) {
  }

  editorConfig: any =  { observer: { timeout: 100, }, 
    buttons: ['font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'eraser', 'ul', 'ol', '|', 'image', 'table', 'link', '|', 'undo', 'redo'], 
    buttonsMD: ['font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'eraser', 'ul', 'ol', '|', 'image', 'table', 'link', '|', 'undo', 'redo'], 
    buttonsSM: ['font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'eraser', 'ul', 'ol', '|', 'image', 'table', 'link', '|', 'undo', 'redo'], 
    buttonsXS: ['font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'eraser', 'ul', 'ol', '|', 'image', 'table', 'link', '|', 'undo', 'redo'], 
    textIcons: false, 
    width: 'auto', 
    height: 'auto', 
    language: 'en', 
    toolbar: true, 
    showCharsCounter: false, 
    showWordsCounter: false, 
    showXPathInStatusbar: false, 
    toolbarButtonSize: 'middle' as any,
    theme: 'default', 
    useSplitMode: false, 
    colorPickerDefaultTab: 'color' as any, 
    uploader: { insertImageAsBase64URI: true}, 
    imageResize: false,
    license: 'K3IKB-0PI70-DR5LL-YOFOY', 
    autocomplete: { sources: [['test', 'case', 'suite',  'analysis', 'coverage', 'specification']], }, 
  };

  ngOnInit() {
    if (this.description)
      this.description = this.sanitizer.sanitize(SecurityContext.HTML, this.description);
    if (this.description && this.form.controls[formControlConstants.Description])
      this.form.controls[formControlConstants.Description].patchValue(this.description);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['description'] && this.joditComponent) {
      this.joditComponent.value = this.description || '';
    }
    this.setEditorValue();
    this.setEditorReadonlyOrDisabled();
  }

  ngAfterViewInit(): void {
    this.setEditorValue();
    this.setEditorReadonlyOrDisabled();
  }

  private setEditorValue(): void {
    if (this.joditComponent) {
      setTimeout(() => {
        if (this.description !== undefined && this.description !== null) {
          this.joditComponent.value = this.sanitizer.sanitize(SecurityContext.HTML,this.description);
        } else {
          this.joditComponent.value = '';
        }
      });
    }
  }

  private setEditorReadonlyOrDisabled(): void {
    if (this.joditComponent) {
      if (this.readOnly) {
        this.joditComponent.setDisabledState(true);
      } else {
        this.joditComponent.setDisabledState(false);
      }
    }
  }

  onChange(event: any): void {
    const content = event;
    if (!content) return;

    if (!this.joditComponent) return;
      this.form.controls[formControlConstants.Description].patchValue(this.sanitizer.sanitize(SecurityContext.HTML, this.joditComponent.value));
  }

  onBeforeCommand(event: any) {
  }

  get isValid() {

    let formControl = this.form.controls[formControlConstants.Description];

    if (!formControl)
      return false;

    if (formControl.disabled) {
      return true;
    }
    return formControl.valid;
  }
}