import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ApprovalDto } from 'src/app/models/dto/approvals/approvalDto';
import { ApolloClientService } from './apollo-client.service';
import { GET_APPROVALS_FOR_ITEM_QUERY } from 'src/app/graphQL/queries/review-queries';
import { GraphQlApprovalOutput } from 'src/app/graphQL/models/output/graphql-review-output';
import { convertGraphQlApprovalOutputToApprovalDto } from 'src/app/graphQL/mappers/review.mappers';

@Injectable({
  providedIn: 'root',
})
export class ApprovalsApiService {

  constructor(private apolloClientService: ApolloClientService) {}

  getApprovals(itemUid: string, itemVersion: number): Observable<ApprovalDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllApprovalsByItem: GraphQlApprovalOutput[]}>({
          query: GET_APPROVALS_FOR_ITEM_QUERY,
          variables: {ItemUid: itemUid, Version: itemVersion}
        })
      ),
      map(results => {
        const approvals = results?.data?.configuratorAllApprovalsByItem;
        if (!approvals) 
          return undefined;

        return approvals.map(approval => {
          return convertGraphQlApprovalOutputToApprovalDto(approval);
        })
      })
    )
  }
}
