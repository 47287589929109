import { gql } from "apollo-angular";

export const GETALL_TEST_ANALYSIS_QUERY = gql`query configuratorAllTestAnalysis($Company: String, $RegBy: String, $OrgGroup: String) {
    configuratorAllTestAnalysis(Company: $Company, RegBy: $RegBy, OrgGroup: $OrgGroup) {
        Version
        Uid
        ItemNo
        Name
        ModifiedBy
        ModificationTime
        LockedTime
        LockedBy
        CreationTime
        CreatedBy
        Comment
        ApprovedForTestLevel
        ApprovalStatus
    }
  }
  `

  export const GET_TEST_ANALYSIS_QUERY = gql`query configuratorTestAnalysisById($Uid: String!) {
    configuratorTestAnalysisById(Uid: $Uid) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

  export const GET_TEST_ANALYSIS_UI_TEMPLATE_QUERY = gql`query configuratorTestAnalysisUiTemplate {
    configuratorTestAnalysisUiTemplate {
        Name
        Category
        DefaultValue
        FieldType
        IsMultiValue
        HasHierarchyValues
        IsReadOnly
        Label
        IsRequired
        Order
        Tooltip
        ValueList
    }
  }
  `
  