export namespace TestCoverageActions {
    export class SetAllTestCoverages {
      static readonly type = '[testCoverage] SetAllTestCoverages';
      constructor() {}
    }
    export class SetMyTestCoverages {
      static readonly type = '[testCoverage] SetMyTestCoverages';
      constructor(public userId: string) {}
    }
  
    export class DeleteTestCoverage {
      static readonly type = '[testCoverage] DeleteTestCoverage';
      constructor(public testCoverageUid: string, public regBy: string) {}
    }
    
    export class SetUpdateNeeded {
      static readonly type = '[testCoverage] SetUpdateNeeded';
      constructor(public updateNeeded: boolean) {}
    }
  }
  