import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ItemTableFilter } from 'src/app/models/enums/item-table-filter-enum';
import { formField } from 'src/app/models/formField';
import { GitlabScriptService } from 'src/app/services/api/gitlab-script.service';
import { AuthService } from 'src/app/services/auth.service';
import { LinkValidationService } from 'src/app/services/link-validation.service';
import { FormFieldOption } from 'src/app/models/formFieldOption';
import { GitLabScript } from 'src/app/models/dto/gitLabScript';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { Store } from '@ngxs/store';
import { GitLabScriptsActions } from 'src/app/store/gitLabScripts/gitLabScripts.actions';
import { FieldTypeEnum } from 'src/app/models/enums/fieldTypeEnum';
import { TestCasesActions } from 'src/app/store/testCases/testCases.actions';
import { TestCasesState } from 'src/app/store/testCases/testCases.state';
import { RouteConstants } from 'src/app/constants/routeConstants';
import { TestAnalysisActions } from 'src/app/store/testAnalysis/testAnalysis.actions';
import { TestAnalysisState } from 'src/app/store/testAnalysis/testAnalysis.state';

@Component({
  selector: 'app-linked-item-list',
  templateUrl: './linked-item-list.component.html',
  styleUrls: ['./linked-item-list.component.scss'],
  providers: [MessageService]
})
export class LinkedItemListComponent implements OnInit, OnChanges {
  @Input() field!: formField;
  @Input() form!: FormGroup;
  @Input() locked: boolean;
  @Input() items: any[];


  itemTableFilter: ItemTableFilter = ItemTableFilter.AllItems;
  currentUserSubscription: Subscription;
  username: string = '';
  company: string;

  showSearch: boolean = false;
  showVersion: boolean = false;
  linkedItems: FormFieldOption[] = [];
  FormControlConstants = formControlConstants;
  RouteConstants = RouteConstants;

  tokenField: formField;
  projectIdField: formField;
  gitlabSettingsFormGroup: FormGroup = new FormGroup({
    token: new FormControl('', Validators.required),
    projectId: new FormControl(14739, Validators.required)
  });

  constructor(private gitlabScriptService: GitlabScriptService,
    private linkValidationService : LinkValidationService,
    private messageService: MessageService,
    private authService: AuthService,
    private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.field || !this.form)
      return; 

    let value = this.form.controls[this.field.name]?.value;
    if (value) {
      this.linkedItems = this.convertFormDataToFieldOptions(value);
      this.showVersion = true;
    }
  }

  ngOnInit(): void {
    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.username = result.userName;
      this.company =  result.companyName;
    });

    this.tokenField = ({
      name: 'token',
      label: "Token",
      isRequired: true,
      fieldType: FieldTypeEnum.text,
      category: '',
      defaultValue: '',
      order: 0,
      tooltip: '',
      isReadOnly: false,
      isMultiValue: false,
      hasHierarchyValues: false,
    });
    this.projectIdField = ({
      name: 'projectId',
      label: "Project id",
      isRequired: true,
      fieldType: FieldTypeEnum.text,
      category: '',
      defaultValue: '',
      order: 0,
      tooltip: '',
      isReadOnly: false,
      isMultiValue: false,
      hasHierarchyValues: false,
    });

    this.filterItems();
  }

  displaySearch() {
    this.showSearch = true;
  }

  addItem(item: FormFieldOption) {
    if(this.field.name != formControlConstants.TCGitLabScript) {
      this.ValidateAndAddLink(item);
      return;
    }

    this.gitlabScriptService.fetchForeignSystemKeyDto(item.data as GitLabScript).subscribe(result => {
      let link: FormFieldOption = {
        key: result.path,
        value: result.name,
        data: result,
      }
      this.ValidateAndAddLink(link);
    });
  }

  private ValidateAndAddLink(link: FormFieldOption) {
    this.linkValidationService.validateLink(link.key, this.linkedItems.map(x => {return x.key}), this.field.name).then(errorMessage => {
      if(errorMessage) {
        this.messageService.add({severity:'error', summary: 'Not allowed!', detail: errorMessage, icon: 'pi-times'});
      } else {
        this.linkedItems.push(link);
        this.form.patchValue({ [this.field.name]: this.convertFormFieldOptionsToData(this.linkedItems) });
      }
    });  
    this.showVersion = true;
  }

  private convertFormFieldOptionsToData(items: FormFieldOption[]) {
    let result: any[] = [];
    items?.forEach(item => {
      if(item && item.data) 
        result.push(item.data)
    });
    return result;
  }

  private convertFormDataToFieldOptions(items: BaseTestItemDto[] | GitLabScript[]) {
    let result: FormFieldOption[] = [];
    items?.forEach(item => {
      if(item && (this.field.name == this.FormControlConstants.TATestCase || this.field.name == this.FormControlConstants.TSpecTestAnalysis)) 
        result.push({key: item.uid, value: item.name, data: item})
      else if (item && this.field.name == this.FormControlConstants.TCGitLabScript) 
      result.push({key: item.path, value: item.name, data: item})
    });
    return result;
  }

  removeItem(item) {
    const index = this.linkedItems.indexOf(item);
    this.linkedItems.splice(index, 1);

    this.form.patchValue({ [this.field.name]: this.convertFormFieldOptionsToData(this.linkedItems) });
    if(this.form.value[this.field.name].length == 0)
      this.showVersion = false;
  }

  filterItems(){
    if(this.field.name === formControlConstants.TATestCase){
      this.store.dispatch(new TestCasesActions.SetAllTestCases(this.company));
            
      this.store.select<any>(TestCasesState.getAllTestCases).subscribe((result) => {
        if(result != undefined && result.length > 0){
          if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe)
            this.field.options = result.filter(x => x.createdBy?.toLowerCase() === this.username.toLowerCase()).map(x => {return {key: x.uid, value: x.name, data: x}});
          else 
            this.field.options = [...result].map(x => {return {key: x.uid, value: x.name, data: x}});
        }
      });
    }
    else if(this.field.name === formControlConstants.TSpecTestAnalysis){
      this.store.dispatch(new TestAnalysisActions.SetAllTestAnalysis(this.company));
            
      this.store.select<any>(TestAnalysisState.getAllTestAnalysis).subscribe((result) => {
        if(result != undefined){
          if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe)
            this.field.options = result.filter(x => x.createdBy?.toLowerCase() === this.username.toLowerCase()).map(x => {return {key: x.uid, value: x.name, data: x}});
          else 
            this.field.options = [...result].map(x => {return {key: x.uid, value: x.name, data: x}});
        }
      });
    }
    else {
      if(this.items)
        this.field.options = [...this.items];
    }
  }

  saveGitlabSettings(){
    this.gitlabScriptService.setToken(this.gitlabSettingsFormGroup.controls.token.value);
    this.store.dispatch(new GitLabScriptsActions.SetAllGitLabScriptsForProject(this.gitlabSettingsFormGroup.controls.projectId.value));
  }

  onRowReorder(reorderIndex: any){
    this.form.patchValue({ [this.field.name]: this.convertFormFieldOptionsToData(this.linkedItems) });
  }
}
