import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestSuitesActions } from './testSuites.actions';
import { TestSuitesStateModel } from './testSuites.state.model';
import { patch, removeItem } from '@ngxs/store/operators';
import { TestSuiteApiService } from 'src/app/services/api/test-suite-api.service';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';

@State<TestSuitesStateModel>({
  name: 'testSuites',
  defaults: {
    testSuites: [],
    orgGroupTestSuites: [],
    functionalGroupTestSuites: [],
    myTestSuites: [],
    updateNeeded : false
  },
})
@Injectable()
export class TestSuitesState {
  pageNumber = 1;
  pageSize = 10;
  constructor(private testSuiteApiService: TestSuiteApiService) {}

  @Action(TestSuitesActions.SetAllTestSuites)
  SetAllTestSuites(ctx: StateContext<TestSuitesStateModel>, action :TestSuitesActions.SetAllTestSuites) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.testSuites !== undefined && state.testSuites.length > 0) 
      return;

    this.testSuiteApiService.getAll(action.company, '', '').subscribe(testSuites =>{
      ctx.patchState({ testSuites: testSuites });
    });
  }

  @Action(TestSuitesActions.SetOrgGroupTestSuites)
  SetOrgGroupTestSuites(ctx: StateContext<TestSuitesStateModel>, action: TestSuitesActions.SetOrgGroupTestSuites) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.orgGroupTestSuites !== undefined && state.orgGroupTestSuites.length > 0) 
      return;

    this.testSuiteApiService.getAll('', '', action.orgGroupId ).subscribe(testSuites =>{
        ctx.patchState({ orgGroupTestSuites: testSuites });
    });
  }

  @Action(TestSuitesActions.SetMyTestSuites)
  SetMyTestSuites(ctx: StateContext<TestSuitesStateModel>, action: TestSuitesActions.SetMyTestSuites) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.myTestSuites !== undefined && state.myTestSuites.length > 0) 
      return;

    this.testSuiteApiService.getAll('', action.userId, '' ).subscribe(testSuites =>{
        ctx.patchState({ myTestSuites: testSuites });
    });
  }

  @Action(TestSuitesActions.SetFunctionalGroupTestSuites)
  SetFunctionalGroupTestSuites(ctx: StateContext<TestSuitesStateModel>, action: TestSuitesActions.SetFunctionalGroupTestSuites) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.functionalGroupTestSuites !== undefined && state.functionalGroupTestSuites.length > 0) 
      return;

    for (let index = 0; index < action.functionalGroupIds.length; index++) {
      const fg = action.functionalGroupIds[index];
      this.testSuiteApiService.getAll('', fg ).subscribe(result =>{
        if(result && result.length > 0) {
          ctx.patchState({ functionalGroupTestSuites: result });
          return;
        }
      });
    }
  }

  @Action(TestSuitesActions.DeleteTestSuite)
  DeleteTestSuite(ctx: StateContext<TestSuitesStateModel>, action: TestSuitesActions.DeleteTestSuite){

    this.testSuiteApiService.delete(action.testSuiteUid, action.regBy)
      .subscribe(result =>{
        ctx.setState(
          patch<TestSuitesStateModel>({
            orgGroupTestSuites: removeItem<BaseTestItemDto>(x => x.uid === action.testSuiteUid),
            testSuites: removeItem<BaseTestItemDto>(x => x.uid === action.testSuiteUid)
          })
        )
      });
  }

  @Action(TestSuitesActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestSuitesStateModel>, action: TestSuitesActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }

  @Selector()
  static getAllTestSuites(state: TestSuitesStateModel) {
    return state.testSuites;
  }

  @Selector()
  static getOrgGroupTestSuites(state: TestSuitesStateModel) {
    return state.orgGroupTestSuites;
  }

  @Selector()
  static getMyTestSuites(state: TestSuitesStateModel) {
    return state.myTestSuites;
  }

  @Selector()
  static getFgTestSuites(state: TestSuitesStateModel) {
    return state.functionalGroupTestSuites;
  }
}
