export namespace TestSuitesActions {
  export class SetAllTestSuites {
    static readonly type = '[TestSuites] SetAllTestSuites';
    constructor(public company: string) {}
  }

  export class SetOrgGroupTestSuites {
    static readonly type = '[TestSuites] SetOrgGroupTestSuites';
    constructor(public orgGroupId: string) {}
  }

  export class SetMyTestSuites {
    static readonly type = '[TestSuites] SetMyTestSuites';
    constructor(public userId: string) {}
  }
  
  export class SetFunctionalGroupTestSuites {
    static readonly type = '[TestSuites] SetFunctionalGroupTestSuites';
    constructor(public functionalGroupIds: string[]) {}
  }

  export class DeleteTestSuite {
    static readonly type = '[TestSuites] DeleteTestSuite';
    constructor(public testSuiteUid: string, public regBy: string) {}
  }

  export class SetUpdateNeeded {
    static readonly type = '[TestSuites] SetUpdateNeeded';
    constructor(public updateNeeded: boolean) {}
  }
}
