import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListboxModule } from 'primeng/listbox';
import { TpcPropertyComponent } from './components/tpc-property/tpc-property.component';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PreviewChangesComponent } from './components/preview-changes/preview-changes.component';
import { NgxsModule } from '@ngxs/store';
import { TpcState } from './store/tpc/tpc.state';
import { TestLevelsState } from './store/testLevels/testLevels.state';
import { SpecificationComponent } from './components/specification/specification.component';
import { ItemEvaluationComponent } from './components/item-evaluation/item-evaluation.component';
import { LinkedRequirementsComponent } from './components/linked-requirements/linked-requirements.component';
import { ApprovalRequestsComponent } from './components/approval-requests/approval-requests.component';
import { SopState } from './store/sop/sop.state';
import { LinkedItemListComponent } from './components/linked-item-list/linked-item-list.component';
import { TestCasesState } from './store/testCases/testCases.state';
import { LinkedItemListSearchComponent } from './components/linked-item-list-search/linked-item-list-search.component';
import { OrganizationalGroupsState } from './store/organizationalGroups/organizationalGroups.state';
import { PanelModule } from 'primeng/panel';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ItemRegistrationInfoComponent } from './components/item-registration-info/item-registration-info.component';
import { EvaluationStatisticsComponent } from './components/evaluation-statistics/evaluation-statistics.component';
import { ToastModule } from 'primeng/toast';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { EcuState } from './store/ecu/ecu.state';
import { StepsComponent } from './components/steps/steps.component';
import { OffboardComponentsState } from './store/offboardComponents/offboardComponents.state';
import { ProductPropertiesState } from './store/productPropeties/productProperties.state';
import { GitLabScriptsState } from './store/gitLabScripts/gitLabScripts.state';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LinkedItemHierarchyListComponent } from './components/linked-item-hierarchy-list/linked-item-hierarchy-list.component';
import { TreeTableModule } from 'primeng/treetable';
import { ViewParentItemsComponent } from './components/view-parent-items/view-parent-items.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TestAnalysisState } from './store/testAnalysis/testAnalysis.state';
import { SpinnerComponent } from './components/common/spinner/spinner.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConnectedPolarionItemsComponent } from './components/connected-polarion-items/connected-polarion-items.component';
import { TestItemListComponent } from './pages/test-item-list/test-item-list.component';
import { DynamicFormFieldComponent } from './components/dynamic-form-field/dynamic-form-field.component';
import { TestCaseComponent } from './pages/test-case/test-case.component';
import { TestSuiteComponent } from './pages/test-suite/test-suite.component';
import { TestAnalysisComponent } from './pages/test-analysis/test-analysis.component';
import { TestItemActionComponent } from './components/shared/test-item-action/test-item-action.component';
import { TestObjectsState } from './store/testObjects/testObjects.state';
import { TestCoverageComponent } from './pages/test-coverage/test-coverage.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RmsComparisonComponent } from './pages/rms-comparison/rms-comparison.component';
import { TestSpecificationComponent } from './pages/test-specification/test-specification.component';
import { TestSuitesState } from './store/testSuites/testSuites.state';
import { TestSpecificationsState } from './store/testSpecifications/testSpecifications.state';
import { ReviewCommentsComponent } from './components/review/review-comments/review-comments.component';
import { ReviewHeaderComponent } from './components/review/review-header/review-header.component';
import { ReviewComponent } from './pages/review/review.component';
import { SendToReviewModalComponent } from './components/review/send-to-review-modal/send-to-review-modal.component';
import { UserFunctionsState } from './store/user-functions/user-functions.state';
import { FpcComponent } from './components/fpc/fpc.component';
import { FpcsState } from './store/fpcs/fpcs.state';
import { LinkedHierarchyListSearchComponent } from './components/linked-hierarchy-list-search/linked-hierarchy-list-search.component';
import { UserFunctionsComponent } from './components/user-functions/user-functions.component';
import { FunctionalGroupsState } from './store/functionalGroups/functionalGroups.state';
import { TestCoverageState } from './store/testCoverage/testCoverage.state';
import { NgxJoditProComponent } from 'ngx-jodit-pro';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TpcPropertyComponent,
    PreviewChangesComponent,
    SpecificationComponent,
    ItemEvaluationComponent,
    LinkedRequirementsComponent,
    ApprovalRequestsComponent,
    LinkedItemListComponent,
    LinkedItemListSearchComponent,
    ItemRegistrationInfoComponent,
    EvaluationStatisticsComponent,
    TextEditorComponent,
    StepsComponent,
    LinkedItemHierarchyListComponent,
    ViewParentItemsComponent,
    SpinnerComponent,
    ConnectedPolarionItemsComponent,
    TestItemListComponent,
    DynamicFormFieldComponent,
    TestCaseComponent,
    TestSuiteComponent,
    TestAnalysisComponent,
    TestItemActionComponent,
    TestCoverageComponent,
    RmsComparisonComponent,
    TestSpecificationComponent,
    ReviewHeaderComponent,
    ReviewCommentsComponent,
    SendToReviewModalComponent,
    ReviewComponent,
    FpcComponent,
    LinkedHierarchyListSearchComponent,
    UserFunctionsComponent,
    // NgxJoditProComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    TooltipModule,
    InputSwitchModule,
    ConfirmDialogModule,
    InputTextModule,
    TreeTableModule,
    DropdownModule,
    ListboxModule,
    TreeModule,    
    ContextMenuModule,
    AutoCompleteModule,
    PanelModule,
    NgxChartsModule,
    DialogModule,
    ProgressSpinnerModule,
    ChipsModule,
    ChipModule,
    ToastModule,
    RadioButtonModule,
    MultiSelectModule,
    NgxJoditProComponent,
    NgxsModule.forRoot([
      TpcState,
      FpcsState,
      GitLabScriptsState,
      TestLevelsState,
      EcuState,
      SopState,
      TestCasesState,
      TestSuitesState,
      OrganizationalGroupsState,
      OffboardComponentsState,
      ProductPropertiesState, 
      TestAnalysisState,
      TestCoverageState,
      TestSpecificationsState,
      TestObjectsState,
      UserFunctionsState,
      FunctionalGroupsState
    ]),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
