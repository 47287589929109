export const formControlConstants = {
    Name: 'Name',
    Label: 'Label',
    InputTypeName: 'InputTypeName',
    InputTypeValues: 'InputTypeValues',
    DerivedFrom: 'DerivedFrom',
    TestType: 'TestType',
    DetailLevel: 'DetailLevel',
    RestrictedViewAccess: 'RestrictedViewAccess',
    ObjectUnderTestType: 'ObjectUnderTestType',
    ItemNo: 'ItemNo',
    Environment: 'Environment',
    FPC: 'FPC',
    TPC: 'TPC',
    SOPStart: 'SOPStart',
    SOPEnd: 'SOPEnd',
    ShowNextTestCaseAutomatically: 'ShowNextTestCaseAutomatically',
    PromptForMileage: 'PromptForMileage',
    TestedWithExploratoryTestingOnly: 'TestedWithExploratoryTestingOnly',
    RationaleForRiskAssessment: 'RationaleForRiskAssessment',
    ImpactIfFailureAppears: 'ImpactIfFailureAppears',
    ProbabilityOfFailure: 'ProbabilityOfFailure',
    TotalRiskLevel: 'TotalRiskLevel',
    Description: 'Description',
    Steps: 'Steps',


    TCOwner: 'Lnk-TestCase-GroupOwner',
    TCFunctionalGroup: 'Lnk-TestCase-FunctionalGroup',
    TCEcu: 'Lnk-TestCase-EcuName',
    TCProductProperty: 'Lnk-TestCase-ProductProperty',
    TCOffboardComponent: 'Lnk-TestCase-OffboardComponent',
    TCUserFunction: 'Lnk-TestCase-UserFunction',
    TCTestGroup: 'Lnk-TCAlternative-TestGroup',
    TCTestFunctionalGroup: 'Lnk-TCAlternative-TestFunctionalGroup',
    TCTestObject: 'Lnk-TCAlternative-TestObject',
    TCGitLabScript: 'Lnk-TestCaseAlternative-GitLabScript',

    TSOwner: 'Lnk-TestSuite-GroupOwner',
    TSFunctionalGroup: 'Lnk-TestSuite-FunctionalGroup',
    TSProductProperty: 'Lnk-TestSuite-ProductProperty',
    TSOffboardComponent: 'Lnk-TestSuite-OffboardComponent',
    TSUserFunction: 'Lnk-TestSuite-UserFunction',
    TSTestCase: 'Lnk-TestSuite-TestCase',
    TSTestSuite: 'Lnk-TestSuite-TestSuite',

    TAOwner:'Lnk-TestAnalyzeAlternative-OrganizationalGroup',
    TAFunctionalGroup: 'Lnk-TestAnalyzeAlternative-FunctionalGroup',
    TATestedByOrganizationalGroup: 'Lnk-TestAnalyzeAlternative-TestedByOrganizationalGroup',
    TATestedByFunctionalGroup: 'Lnk-TestAnalyzeAlternative-TestedByFunctionalGroup',
    TATestCase: 'Lnk-TestAnalyzeAlternative-TestCase',
    TATestLevels: 'Lnk-TestAnalyzeAlternative-TestLevels',
    TADelegatedToTestLevels: 'Lnk-ExcludedForTest-TestLevel',
    TARequirement: 'Lnk-TestAnalyze-PolarionRequirement',
    TASpecification: 'Lnk-TestAnalyzeAlternative-PolarionSpecification',

    TestCoverageSpecification: "Lnk-TestPlan-PolarionSpecification",

    ComparisonSpecification: 'Lnk-Comparison-PolarionSpecification',

    TSpecOwner: 'Lnk-TestSpecification-GroupOwner',
    TSpecFunctionalGroup: 'Lnk-TestSpecification-FunctionalGroup',
    TSpecTestAnalysis: 'Lnk-TestSpecification-TestAnalyze',

  };

