import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TestItemActionButton } from 'src/app/components/shared/test-item-action/test-item-action.component';
import { formControlConstants } from 'src/app/constants/formControlConstants';
import { RouteSegments } from 'src/app/constants/routeConstants';
import { SpecificationDto } from 'src/app/models/dto/polarion/specificationDto';
import { TestCoverageDto } from 'src/app/models/dto/testCoverageDto';
import { ItemType } from 'src/app/models/enums/itemType';
import { formField } from 'src/app/models/formField';
import { PreviewChange } from 'src/app/models/previewChange';
import { SpecificationEvaluation } from 'src/app/models/specificationEvaluation';
import { ItemsService } from 'src/app/services/api/items.service';
import { TestCoverageApiService } from 'src/app/services/api/test-coverage-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonService } from 'src/app/services/button.service';
import { ChangesService } from 'src/app/services/changes.service';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';
import { createFormGroup, disableAllFields, enableAllFields } from 'src/app/services/helpers/formHelper';
import { ItemFactory } from 'src/app/services/item-factory/item.factory';
import { TestCoverageActions } from 'src/app/store/testCoverage/testCoverage.actions';

@Component({
  selector: 'test-coverage',
  templateUrl: './test-coverage.component.html',
  styleUrls: ['./test-coverage.component.scss'],
  providers: [MessageService]
})
export class TestCoverageComponent implements OnInit {

  @Input() testCoverageUid: string = '';

  fields: formField[];
  testItem: TestCoverageDto;
  selectedSpecification: SpecificationEvaluation;
  loggedInUsername: string;
  form: FormGroup;
  actionButtons: TestItemActionButton;
  formInitialValue: string;
  changes: PreviewChange[] = [];
  specificationField: formField;

  addPageActive: boolean = false;
  hasChange: boolean = false;
  specificationsPopulated: boolean = false;
  hideForm: boolean = false;

  currentUserSubscription: Subscription;
  private subscriptions: Subscription[] = [];

  itemType = ItemType.TestCoverage


  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private testCoverageApiService: TestCoverageApiService,
    public checkOutItemService: CheckedOutItemService,
    private itemsService: ItemsService,
    private changesService: ChangesService,
    private messageService: MessageService,
    private buttonService: ButtonService,
    private store: Store) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let itemUidRouteParameter = params.get("testitemuid");

      this.actionButtons = this.buttonService.setDefaultInit();
      this.addPageActive = itemUidRouteParameter === RouteSegments.ADD;
      if (this.addPageActive) {
        this.actionButtons = this.buttonService.setAddButtons();
        this.hasChange = true;
        this.testCoverageUid = '';
        this.loadData();
      }
      else {
        this.testCoverageUid = itemUidRouteParameter;
        this.testCoverageApiService.getByUid(this.testCoverageUid).subscribe(response => { 
          if (!response.success) {
            this.hideForm = true;
            this.clearValidationErrors();
            if(response.responseMessages?.length > 0)
              this.messageService.add({ severity: 'error', summary: response.responseMessages[0], sticky: true});
          }
          else
          {
            this.testItem = response.returnValue;
            this.loadData();
          }
        });
      }
    });
  }

  loadData(){
    this.currentUserSubscription = this.authService.userData$.subscribe(result => this.loggedInUsername = result.userName);
    this.setButtonState();
    this.testCoverageApiService.getUiTemplate().subscribe(result => {
      this.fields = result.sort(x => x.order);
      this.loadFields();
      this.form = createFormGroup(this.fields, ItemType.TestCoverage);
      if(this.testItem) {
        this.setControlState();
        this.populatePropertiesToFormGroupFromDto();

        this.formInitialValue = JSON.stringify(this.form.value);

        //if item is locked start tracking changes
        if (this.testItem.lockedBy == this.loggedInUsername) 
          this.onChanges();
      }
    });
  }

  loadFields(){
    this.specificationField = this.fields?.find(x => x.category === "General information" && x.name === formControlConstants.TestCoverageSpecification);
  }

  populatePropertiesToFormGroupFromDto(){
    
    this.form.get('Name').setValue(this.testItem['name']);
    this.form.controls['Lnk-TestPlan-PolarionSpecification'].patchValue(this.testItem.specifications)

    this.specificationsPopulated = true;
  }

  setButtonState(){
    this.buttonState();
    //subscribe to change of checked out item
    let subscription = this.checkOutItemService.checkedItemUidChange.subscribe(itemUid => this.buttonState());
    this.subscriptions.push(subscription);
  }

  buttonState(){ 
    if(this.addPageActive)
      return;

    if(this.checkOutItemService.exists() && this.testItem.lockedBy != this.loggedInUsername)
      this.actionButtons = this.buttonService.setReadonlyButtons();
    
    if(/*this.checkOutItemService.exists() && */this.testItem.lockedBy == this.loggedInUsername)//Issue when checked out item and f5
      this.actionButtons = this.buttonService.setCheckedOutButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy != '' && this.testItem.lockedBy != this.loggedInUsername)
      this.actionButtons = this.buttonService.setReadonlyButtons();
    
    if(!this.checkOutItemService.exists() && this.testItem.lockedBy == '')
      this.actionButtons = this.buttonService.setEditButtons();
  }

  setControlState(){
    if(this.actionButtons.stateReadOnly || this.actionButtons.stateEdit)
      disableAllFields(this.fields, this.form);

    if(this.actionButtons.stateAdd || this.actionButtons.stateCheckedOut)
      enableAllFields(this.fields, this.form);
  }

  specificationSelected(value: SpecificationDto) {
    let specificationEvaluation: SpecificationEvaluation = {
      specification: value,
      requirements: []
    };
    this.selectedSpecification = specificationEvaluation;
  }

  onSubmit(): void {
    if(!this.validateForSubmit())
      return;

    let requestDto = ItemFactory.createItem(this.testItem, this.form, ItemType.TestCoverage) as TestCoverageDto;

    if(this.addPageActive){
      this.testCoverageApiService.create(requestDto).subscribe(response => {
        if(response.success)
          this.navigateToList();
        else
          this.showValidationErrors(response.responseMessages);
      });
    } else {
      this.testCoverageApiService.update(requestDto).subscribe(response => {
        if(response.success) {
          this.unlockItem();
          this.navigateToList();
        } else {
          this.showValidationErrors(response.responseMessages);
        }
      });
    }
  }

  validateForSubmit() {
    this.clearValidationErrors();
  
    if(this.form.invalid){
      this.messageService.add({ severity: 'error', summary: 'Not all required fields are populated', sticky: true});
      return false;
    }
  
    if(!this.addPageActive && !this.hasChange) {
      this.messageService.add({ severity: 'error', summary: 'You made no changes', sticky: true});
      return false;
    }
  
    return true;
  }

  showValidationErrors(errors: string[]) {
    this.clearValidationErrors();
    this.messageService.add({ severity: 'error', summary: 'Item not saved!', sticky: true});

    errors.forEach(errorMessage => {
      this.messageService.add({ severity: 'error', summary: errorMessage, sticky: true });
    });
  }

  clearValidationErrors() {
    this.messageService.clear();
  }

  checkOut(): void {
    if (!this.testCoverageUid)
      return;

    this.itemsService.checkout(this.testCoverageUid).subscribe(result => {
      if (result == false) {
        this.messageService.add({ severity: 'error', summary: 'Item check out failed', sticky: false});
        return;
      }
      
      this.actionButtons = this.buttonService.setCheckedOutButtons();
      enableAllFields(this.fields, this.form);
        
      this.testItem.lockedBy = this.loggedInUsername;
      this.testItem.lockedTime = new Date();   

      this.formInitialValue = JSON.stringify(this.form.value);
      this.onChanges();
      this.checkOutItemService.set(this.testItem?.uid, ItemType.TestCoverage);
      this.store.dispatch(new TestCoverageActions.SetUpdateNeeded(true));
      this.hasChange = false;
    });
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(formValue => {
      this.hasChange =  this.formInitialValue !== JSON.stringify(this.form.value);
      
      let currentChanges = this.changesService.getDifferences(
        JSON.parse(this.formInitialValue),
        formValue,
         this.fields,
      );
      this.changes = currentChanges;
    });
  }

  unlockItem(): void {    
    this.clearValidationErrors();

    if (this.addPageActive || this.testItem.lockedBy == '') {
      this.resetChanges();
      return;
    } 

    this.itemsService.unlock(this.testItem.uid).subscribe(() => {
      this.actionButtons = this.buttonService.setEditButtons();
      this.testItem.lockedBy = '';
      this.testItem.lockedTime = null;
      disableAllFields(this.fields, this.form);
      this.resetChanges();
    });
    this.checkOutItemService.set();
    this.store.dispatch(new TestCoverageActions.SetUpdateNeeded(true));
  }

  resetChanges() {
    this.form.reset(this.formInitialValue ? JSON.parse(this.formInitialValue) : {});
    this.hasChange = false;
    this.changes = [];
  }

  private navigateToList(): void {
    window.location.href = `/#/${RouteSegments.TEST_COVERAGE}`;
    window.location.reload();
  }
}
