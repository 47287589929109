import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch, removeItem } from "@ngxs/store/operators";
import { BaseTestItemDto } from "src/app/models/dto/Base-Test-item-dto";
import { TestCoverageApiService } from "src/app/services/api/test-coverage-api.service";
import { TestCoverageActions } from "./testCoverage.actions";
import { TestCoverageStateModel } from "./testCoverage.state.model";

@State<TestCoverageStateModel>({
    name: 'testCoverage',
    defaults: {
        testCoverages: [],
        myTestCoverages: [],
        updateNeeded : false
    },
})
@Injectable()
export class TestCoverageState {
    constructor(private testCoverageApiService: TestCoverageApiService) {}

  @Action(TestCoverageActions.SetAllTestCoverages)
  SetAllTestCoverages(ctx: StateContext<TestCoverageStateModel>) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.testCoverages !== undefined && state.testCoverages.length > 0) return;

    this.testCoverageApiService.getAll('').subscribe((testCoverages) => {
      ctx.patchState({ testCoverages: testCoverages });
    });
  }

  @Action(TestCoverageActions.SetMyTestCoverages)
  SetMyTestCoverages(ctx: StateContext<TestCoverageStateModel>, action: TestCoverageActions.SetMyTestCoverages) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.myTestCoverages !== undefined && state.myTestCoverages.length > 0) return;

    this.testCoverageApiService.getAll(action.userId).subscribe((testCoverages) => {
      ctx.patchState({ myTestCoverages: testCoverages });
    });
  }

  @Action(TestCoverageActions.DeleteTestCoverage)
  DeleteTestCoverage(ctx: StateContext<TestCoverageStateModel>, action: TestCoverageActions.DeleteTestCoverage){

    this.testCoverageApiService.delete(action.testCoverageUid, action.regBy)
      .subscribe(result =>{

        ctx.setState(
          patch<TestCoverageStateModel>({
            testCoverages: removeItem<BaseTestItemDto>(x => x.uid === action.testCoverageUid),
            myTestCoverages: removeItem<BaseTestItemDto>(x => x.uid === action.testCoverageUid)
          })
        )
      });
  }
  
  @Action(TestCoverageActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestCoverageStateModel>, action: TestCoverageActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }
  
  @Selector()
  static getAllTestCoverages(state: TestCoverageStateModel) {
    return state.testCoverages;
  }
  @Selector()
  static getMyTestCoverages(state: TestCoverageStateModel) {
    return state.myTestCoverages;
  }
}