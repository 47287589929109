import { OrganizationalGroup } from "src/app/models/dto/organizationalGroup";
import { GraphQLOrganizationalGroupOutput } from "../models/output/graphql-organizational-group-output";

export function convertGraphqlOrganizationalGroupToDto(input: GraphQLOrganizationalGroupOutput): OrganizationalGroup {
  return {
    uid: input.Uid,
    name: input.Name,
    company: input.Company
  }
}
