export namespace TestSpecificationsActions {
  export class SetAllTestSpecifications {
    static readonly type = '[TestSpecifications] SetAllTestSpecifications';
    constructor(public company: string) {}
  }

  export class SetOrgGroupTestSpecifications {
    static readonly type = '[TestSpecifications] SetOrgGroupTestSpecifications';
    constructor(public orgGroupId: string) {}
  }
  export class SetMyTestSpecifications {
    static readonly type = '[TestSpecifications] SetMyTestSpecifications';
    constructor(public userId: string) {}
  }

  export class SetFunctionalGroupTestSpecifications {
    static readonly type = '[TestSpecifications] SetFunctionalGroupTestSpecifications';
    constructor(public functionalGroupIds: string[]) {}
  }

  export class DeleteTestSpecification {
    static readonly type = '[TestSpecifications] DeleteTestSpecification';
    constructor(public testSpecificationUid: string, public regBy: string) {}
  }

  export class SetUpdateNeeded {
    static readonly type = '[TestSpecifications] SetUpdateNeeded';
    constructor(public updateNeeded: boolean) {}
  }
}
