<p-toast ></p-toast>
<div class="title">
    <h2 *ngIf="testItem">{{ testItem.itemNo }}: {{ testItem.name }}</h2>
    <i class="pi lockIcon"
      *ngIf="testItem && testItem.lockedBy !== ''"
      pTooltip="Locked by {{ testItem.lockedBy }} 
                Since {{ testItem.lockedTime | date: 'dd.MM.yyyy HH:mm' }}"
      style="font-size: initial"
      [class]="testItem?.lockedBy
                ? 'checkedOutByYou pi-unlock'
                : 'checkedOutBySomeoneElse pi-lock' "
    ></i>
</div>

<div *ngIf="form" class="test-coverage-page"  [ngClass]="{'hide-form': this.hideForm}">
    <div class="test-coverage-form">

        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div *ngIf="specificationField">
                <p-panel toggler="header" styleClass="panelHeader" [toggleable]="true">
                    <ng-template pTemplate="header">
                        <p class="boldText">General Information</p>
                    </ng-template>
                    
                    <div *ngFor="let field of fields" class="field-section">
                        <app-dynamic-form-field [field]="field" [form]="form"></app-dynamic-form-field>
                    </div>

                    <div class="sdds-on-white-bg m-right field-section" *ngIf="specificationField && (specificationsPopulated || addPageActive)">
                        <app-specification (newItemEvent)="specificationSelected($event)" [field]="specificationField" [form]="form"
                            [locked]="!addPageActive && testItem?.lockedBy !== loggedInUsername">
                        </app-specification>
                    </div> 
                </p-panel>
            </div>

            <test-item-action [testItem]="testItem" [changes]="changes"  (checkOutTestItem)="checkOut()" 
            (unlockTestItem)="unlockItem()" class="footer" [actionButton]="actionButtons" [itemType]="itemType"></test-item-action>
        </form>

        <app-item-evaluation [selectedSpecification]="selectedSpecification"></app-item-evaluation>
    </div>
    
    <div class="test-coverage-properties">
        <app-item-registration-info [item]="testItem"></app-item-registration-info>
    </div>
</div>