import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResponseDto } from 'src/app/models/dto/responseDto';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestSuiteDto } from 'src/app/models/dto/testSuiteDto';
import { formField } from 'src/app/models/formField';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLBaseTestItemOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { GETALL_TEST_SUITES_QUERY, GET_TEST_SUITE_QUERY, GET_TEST_SUITE_UI_TEMPLATE_QUERY } from 'src/app/graphQL/queries/test-suite-queries';
import { convertGraphqlBaseTestItemToDto, convertGraphqlFormFieldToDto, convertGraphqlResponseToDto, convertGraphqlResponseToDtoSimpleReturnValue } from 'src/app/graphQL/mappers/common.mappers';
import { GraphQLResponseOutput } from 'src/app/graphQL/models/output/graphql-response-output';
import { GraphQLFormFieldOutput } from 'src/app/graphQL/models/output/graphql-form-field-output';
import { convertTestSuiteDtoToGraphqlInput } from 'src/app/graphQL/mappers/test-suite.mapper';
import { CREATE_TEST_SUITE_MUTATION, DELETE_TEST_SUITE_MUTATION, UPDATE_TEST_SUITE_MUTATION } from 'src/app/graphQL/mutations/test-suite-mutations';

@Injectable({
  providedIn: 'root'
})
export class TestSuiteApiService {

  constructor(private apolloClientService: ApolloClientService) { }

  getAll(company: string, regBy: string, functionalGroupId?: string): Observable<BaseTestItemDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllTestSuites : GraphQLBaseTestItemOutput[]}>({
          query: GETALL_TEST_SUITES_QUERY,
          variables: {Company: company, RegBy: regBy, OrgGroup: functionalGroupId}
        })
      ),
      map(results => {
        const testSuites = results.data.configuratorAllTestSuites
        if (!testSuites)
          return undefined

        return testSuites.map(item => {
          return convertGraphqlBaseTestItemToDto(item);
        })
      })
    )
  }

  getByUid(uid: string): Observable<ResponseDto> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestSuiteById: GraphQLResponseOutput}>({
          query: GET_TEST_SUITE_QUERY,
          variables: {Uid: uid}
        })
      ),
      map(results => {
        const testSuite = results.data.configuratorTestSuiteById
        if (!testSuite) 
          return undefined

        return convertGraphqlResponseToDto<TestSuiteDto>(testSuite);
      })
    )
  }

  getUiTemplate(): Observable<formField[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestSuiteUiTemplate: GraphQLFormFieldOutput[]}>({
          query: GET_TEST_SUITE_UI_TEMPLATE_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const testSuiteUiTemplate = results.data.configuratorTestSuiteUiTemplate
        if (!testSuiteUiTemplate)
          return undefined

        return testSuiteUiTemplate.map(item => {
          return convertGraphqlFormFieldToDto(item);
        })
      })
    )
  }


  create(testSuite: TestSuiteDto): Observable<ResponseDto> {
    const graphqlTestSuiteInput = convertTestSuiteDtoToGraphqlInput(testSuite)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateTestSuite: GraphQLResponseOutput}>({
          mutation: CREATE_TEST_SUITE_MUTATION,
          variables: {TestSuite: graphqlTestSuiteInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorCreateTestSuite as GraphQLResponseOutput))
    )
  }

  update(testSuite: TestSuiteDto): Observable<ResponseDto> {
    const graphqlTestSuiteInput = convertTestSuiteDtoToGraphqlInput(testSuite)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorUpdateTestSuite: GraphQLResponseOutput}>({
          mutation: UPDATE_TEST_SUITE_MUTATION,
          variables: {TestSuite: graphqlTestSuiteInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorUpdateTestSuite as GraphQLResponseOutput))
    )
  }

  delete(uid: string, regBy: string): Observable<any> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate({
          mutation: DELETE_TEST_SUITE_MUTATION,
          variables: {Uid: uid}
        })
      ),
      map(result =>(result.data))
    )
  }
}
