import { GitLabScriptInput, ScenarioInput, TestCaseAlternativeInput, TestCaseInput, TestCaseStepInput, UseCaseInput, UserFunctionInput } from "../models/input/graphql-test-case-input";
import { TestCaseDto } from "src/app/models/dto/testCaseDto";
import { TestCaseAlternativeDto } from "src/app/models/dto/testCaseAlternativeDto";
import { GitLabScriptDto } from "src/app/models/dto/gitLabScriptDto";
import { StepDto } from "src/app/models/dto/stepDto";
import { Scenario, UseCase, UserFunction } from "src/app/models/dto/user-function-dto";

  export function convertTestCaseDtoToGraphqlInput(input: TestCaseDto): TestCaseInput {
    return {
      Uid: input.uid,
      ItemNo: input.itemNo,
      Name: input.name,
      Version: input.version,
      ModifiedBy: input.modifiedBy,
      ModificationTime: null, //input.ModificationTime,
      Comment: input.comment,
      LockedBy: input.lockedBy,
      LockedTime: null, //input.lockedTime ? input.lockedTime.toISOString() : null,
      CreatedBy: input.createdBy,
      CreationTime: null, //  input.creationTime ? new Date(new Date(input.creationTime + 'Z').toLocaleString()).toDateString() : null,
      ApprovalStatus: input.approvalStatus,
      ApprovedForTestLevel: input.approvedForTestLevel,
      Label: input.label,
      InputTypeName: input.inputTypeName,
      InputTypeValues: input.inputTypeValues,
      DerivedFrom: input.derivedFrom,
      DetailLevel: input.detailLevel,
      RestrictedViewAccess: input.restrictedViewAccess,
      TestType: input.testType,
      ObjectUnderTestType: input.objectUnderTestType,
      OwnerIds: input.ownerIds,
      EcuIds: input.ecuIds,
      OffboardComponentIds: input.offboardComponentIds,
      ProductPropertyIds: input.productPropertyIds,
      UserFunctions: input.userFunctions?.map(item => {
        return convertUserFunctionToGraphqlInput(item)
      }),
      Description: input.description,
      Alternative: convertTestCaseAlternativeDtoToGraphqlInput(input.alternative),
      Steps: input.steps?.map(item => {
        return convertStepDtoToGraphqlInput(item)
      })
    }
  }

  export function convertTestCaseAlternativeDtoToGraphqlInput(input: TestCaseAlternativeDto ): TestCaseAlternativeInput  {
    return{
      Uid: input.uid,
      Environment: input.environment,
      Fpc: input.fpc,
      Tpc: input.tpc,
      TestGroups: input.testGroups,
      TestObjects: input.testObjects,
      GitLabScript: input.gitLabScript.map(item => {
        return convertGitLabScriptDtoToGraphqlInput(item);
      }),
    }
  }

  export function convertGitLabScriptDtoToGraphqlInput(input: GitLabScriptDto ): GitLabScriptInput  {
    return{
      Name: input.name,
      Path: input.path,
      ForeignSystemKey: input.foreignSystemKey,
    }
  }


  export function convertStepDtoToGraphqlInput(input: StepDto ): TestCaseStepInput  {
    return{
      No: input.no,
      PreCondition: { Key: input.preCondition.key, Value: input.preCondition.value },
      Stimuli: { Key: input.stimuli.key, Value: input.stimuli.value },
      ExpectedResponse: { Key: input.expectedResponse.key, Value: input.expectedResponse.value },
      PostCondition: { Key: input.postCondition.key, Value: input.postCondition.value },
    }
  }


  export function convertUserFunctionToGraphqlInput(input: UserFunction ): UserFunctionInput  {
    return{
      Id: input.id,
      Name: input.name,
      UseCases: input.useCases?.map(x => {
        return convertUseCaseToGraphqlInput(x);
      })
    }
  }

  export function convertUseCaseToGraphqlInput(input: UseCase ): UseCaseInput  {
    return{
      Id: input.id,
      Name: input.name,
      Scenarios: input.scenarios?.map(x => {
        return convertScenarioToGraphqlInput(x);
      })
    }
  }

  export function convertScenarioToGraphqlInput(input: Scenario ): ScenarioInput  {
    return{
      Id: input.id,
      Name: input.name,
    }
  }
