import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { RouteSegments, RouteConstants } from 'src/app/constants/routeConstants';
import { ItemTableFilter } from 'src/app/models/enums/item-table-filter-enum';
import { ItemsService } from 'src/app/services/api/items.service';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { TestCasesState } from 'src/app/store/testCases/testCases.state';
import { TestCasesActions } from 'src/app/store/testCases/testCases.actions';
import { TestAnalysisState } from 'src/app/store/testAnalysis/testAnalysis.state';
import { TestAnalysisActions } from 'src/app/store/testAnalysis/testAnalysis.actions';
import { TestCoverageApiService } from 'src/app/services/api/test-coverage-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ColumnFilterFormElement } from 'primeng/table';
import { TestAnalysisApiService } from 'src/app/services/api/test-analysis-api.service';
import { TestSuiteApiService } from 'src/app/services/api/test-suite-api.service';
import { TestCaseApiService } from 'src/app/services/api/test-case-api.service';
import { MessageService } from 'primeng/api';
import { TestAnalysisDto } from 'src/app/models/dto/testAnalysisDto';
import { TestCaseDto } from 'src/app/models/dto/testCaseDto';
import { TestSuiteDto } from 'src/app/models/dto/testSuiteDto';
import { PermissionService } from 'src/app/services/permission.service';
import { ParentItemBasicInfo } from 'src/app/models/dto/parent-item-basic-info-dto';
import { TestSpecificationsActions } from 'src/app/store/testSpecifications/testSpecifications.actions';
import { TestSpecificationsState } from 'src/app/store/testSpecifications/testSpecifications.state';
import { TestSuitesState } from 'src/app/store/testSuites/testSuites.state';
import { TestSuitesActions } from 'src/app/store/testSuites/testSuites.actions';
import { TestSpecificationApiService } from 'src/app/services/api/test-specification-api.service';
import { TestSpecificationDto } from 'src/app/models/dto/testSpecificationDto';
import { ItemType } from 'src/app/models/enums/itemType';
import { getRouteBase } from 'src/app/services/helpers/routeHelper';
import { ReviewService } from 'src/app/services/review/review.service';
import { CheckedOutItemService } from 'src/app/services/checked-out-item.service';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { TestCoverageActions } from 'src/app/store/testCoverage/testCoverage.actions';
import { TestCoverageState } from 'src/app/store/testCoverage/testCoverage.state';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'test-item-list',
  templateUrl: './test-item-list.component.html',
  styleUrls: ['./test-item-list.component.scss'],
  providers: [MessageService]
})
export class TestItemListComponent implements OnInit {

  items: any[];
  selectedItem: any;
  parentItems: ParentItemBasicInfo[];
  username: string;
  company: string;
  orgGroupId: string = '';
  functionalGroupIds: string[] = [];
  path: string = '';
  sortField: string = 'creationTime';
  sortOrder: number = -1;
  currentUserSubscription: Subscription;
  itemGetSubscription: Subscription;
  orgGroupSubscription: Subscription;
  routes = RouteConstants;
  addRoute: string = RouteSegments.ADD;
  evaluationRoute: string = RouteConstants.TEST_COVERAGE;
  itemTableFilter: ItemTableFilter= ItemTableFilter.ItemsCreatedByMyGroup;
  parentSubscription: Subscription;
  itemType = ItemType;

  constructor(
    private itemsService: ItemsService,
    private testCoverageApiService: TestCoverageApiService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private checkOutItemService: CheckedOutItemService,
    private store: Store,
    private testAnalysisApiService: TestAnalysisApiService,
    private testSuiteApiService: TestSuiteApiService,
    private testCaseApiService: TestCaseApiService,
    private testSpecificationApiService: TestSpecificationApiService,
    private permissionService: PermissionService,
    private reviewService: ReviewService,
    private cookieService: CookieService
  ) { 
    this.items = [];
  }

  ngOnInit(): void {
    let urlSegment: UrlSegment[] = this.route.snapshot.url;
    if (urlSegment && urlSegment.length > 0) {
      let segment: UrlSegment = urlSegment[0];
      this.path = segment.path;

      //get Cookie
      this.getItemFilter();
    
    
    }

    this.currentUserSubscription = this.authService.userData$.subscribe((result) => {
      this.username = result.userName;
      this.company = result.companyName;
    });

    this.store.dispatch(new OrganizationalGroupsActions.SetOrganizationalGroupByUsername(this.username));

    this.orgGroupSubscription = this.store.select<any>(OrganizationalGroupsState.getLoggedInOrganizationalGroup)
      .subscribe((result) => {
        if(result != undefined){
          this.orgGroupId = result.uid;
          this.getItems();
        }
      });
    // this.functionalGroupSubscription = this.store.select<any>(FunctionalGroupsState.getLoggedInFunctionalGroups)
    //   .subscribe((result) => {
    //     if(result != undefined){
    //       this.functionalGroupIds = result.map(x => x.uid);
    //       this.getItems();
    //     }
    //   });
    this.setOrResetUpdateNeeded(false);


    // filter instantly without pressing Enter
    ColumnFilterFormElement.prototype.onModelChange = function (value) {
      this.filterConstraint.value = value;
      if (this.type || value === '') {
        this.dt._filter();
      }
    }

    this.reviewService.setReviewOn(false);
  }

  // loads the TC/TS/TA list based on the filter and the typeId
  getItems() {
    //set cookie
    this.cookieService.set('itemFilter', this.itemTableFilter.valueOf().toString());
    switch(this.path)
    {
     
      case RouteConstants.TEST_SUITES:
        if(this.itemTableFilter === ItemTableFilter.AllItems){
          this.store.dispatch(new TestSuitesActions.SetAllTestSuites(this.company));
          
          this.itemGetSubscription = this.store.select<any>(TestSuitesState.getAllTestSuites)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe){
          this.store.dispatch(new TestSuitesActions.SetMyTestSuites(this.username));
          this.itemGetSubscription = this.store.select<any>(TestSuitesState.getMyTestSuites)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else {
          // this.store.dispatch(new TestSuitesActions.SetFunctionalGroupTestSuites(this.functionalGroupIds));//fg
          
          // this.itemGetSubscription = this.store.select<any>(TestSuitesState.getFgTestSuites)
          this.store.dispatch(new TestSuitesActions.SetOrgGroupTestSuites(this.orgGroupId));
          
          this.itemGetSubscription = this.store.select<any>(TestSuitesState.getOrgGroupTestSuites)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        }
      break;

      case RouteConstants.TEST_CASES:
        if(this.itemTableFilter === ItemTableFilter.AllItems ){
          this.store.dispatch(new TestCasesActions.SetAllTestCases(this.company));
          
          this.itemGetSubscription = this.store.select<any>(TestCasesState.getAllTestCases)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe){
          this.store.dispatch(new TestCasesActions.SetMyTestCases(this.username));
          this.itemGetSubscription = this.store.select<any>(TestCasesState.getMyTestCases)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else {
          // this.store.dispatch(new TestCasesActions.SetFunctionalGroupTestCases(this.functionalGroupIds));//fg
          
          // this.itemGetSubscription = this.store.select<  any>(TestCasesState.getFgTestCases)
          this.store.dispatch(new TestCasesActions.SetOrgGroupTestCases(this.orgGroupId));
          
          this.itemGetSubscription = this.store.select<any>(TestCasesState.getOrgGroupTestCases)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
          
        }
      break;

      case RouteConstants.TEST_ANALYSIS:
        if(this.itemTableFilter === ItemTableFilter.AllItems ){
          this.store.dispatch(new TestAnalysisActions.SetAllTestAnalysis(this.company));
          
          this.itemGetSubscription = this.store.select<any>(TestAnalysisState.getAllTestAnalysis)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe){
          this.store.dispatch(new TestAnalysisActions.SetMyTestAnalysis(this.username));
          this.itemGetSubscription = this.store.select<any>(TestAnalysisState.getMyTestAnalysis)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else {
          // this.store.dispatch(new TestAnalysisActions.SetFunctionalGroupTestAnalysis(this.functionalGroupIds));//fg
          
          // this.itemGetSubscription = this.store.select<any>(TestAnalysisState.getFgTestAnalysis)
          this.store.dispatch(new TestAnalysisActions.SetOrgGroupTestAnalysis(this.orgGroupId));
          
          this.itemGetSubscription = this.store.select<any>(TestAnalysisState.getOrgGroupTestAnalysis)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
          
        }
      break;

      case RouteConstants.TEST_SPECIFICATIONS:
        if(this.itemTableFilter === ItemTableFilter.AllItems){
          this.store.dispatch(new TestSpecificationsActions.SetAllTestSpecifications(this.company));
          
          this.itemGetSubscription = this.store.select<any>(TestSpecificationsState.getAllTestSpecifications)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else if(this.itemTableFilter === ItemTableFilter.ItemsCreatedByMe){
          this.store.dispatch(new TestSpecificationsActions.SetMyTestSpecifications(this.username));
          this.itemGetSubscription = this.store.select<any>(TestSpecificationsState.getMyTestSpecifications)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
        } else {
          // this.store.dispatch(new TestSpecificationsActions.SetFunctionalGroupTestSpecifications(this.functionalGroupIds));//fg
          
          // this.itemGetSubscription = this.store.select<any>(TestSpecificationsState.getFgTestSpecifications)
          this.store.dispatch(new TestSpecificationsActions.SetOrgGroupTestSpecifications(this.orgGroupId));
          
          this.itemGetSubscription = this.store.select<any>(TestSpecificationsState.getOrgGroupTestSpecifications)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
          
        }
      break;

      case RouteConstants.TEST_COVERAGE:
        if(this.itemTableFilter === ItemTableFilter.AllItems ){
          this.store.dispatch(new TestCoverageActions.SetAllTestCoverages());
          
          this.itemGetSubscription = this.store.select<any>(TestCoverageState.getAllTestCoverages)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
          } else {
            this.store.dispatch(new TestCoverageActions.SetMyTestCoverages(this.username));
          this.itemGetSubscription = this.store.select<any>(TestCoverageState.getMyTestCoverages)
            .subscribe((result) => {
              if(result != undefined){
                this.items = [...result];
                this.hasLockedItem();
              }
            });
          }
        break;
    }
  }

  hasLockedItem(){
    const lockedByIndex = this.items.findIndex(x => x.lockedBy?.toLowerCase() === this.username.toLowerCase());
    if (lockedByIndex !== -1) {
      const lockedByItem = this.items.splice(lockedByIndex, 1)[0];
      this.items.unshift(lockedByItem);
      this.sortField = 'lockedBy';//Change default table sorting
    }
    else
      this.sortField = 'creationTime';
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }

  getItemFilter()
  {
    //get Cookie 
    const cookieValue = this.cookieService.get('itemFilter'); 
    // if(cookieValue ==  undefined)
    //   this.itemTableFilter = ItemTableFilter.ItemsCreatedByMyGroup
    if(cookieValue == '0')
      this.itemTableFilter = ItemTableFilter.AllItems
    if(cookieValue == '1')
      this.itemTableFilter = ItemTableFilter.ItemsCreatedByMyGroup
    if(cookieValue == '2')
      this.itemTableFilter = ItemTableFilter.ItemsCreatedByMe
    if(this.path === RouteConstants.TEST_COVERAGE && this.itemTableFilter == ItemTableFilter.ItemsCreatedByMyGroup)
      this.itemTableFilter = ItemTableFilter.AllItems
  }

  setOrResetUpdateNeeded(updateNeeded : boolean){
    switch(this.path)
    {
      case RouteConstants.TEST_SUITES:
        this.store.dispatch(new TestSuitesActions.SetUpdateNeeded(updateNeeded));
        break;
      case RouteConstants.TEST_CASES:
        this.store.dispatch(new TestCasesActions.SetUpdateNeeded(updateNeeded));
        break;
      case RouteConstants.TEST_ANALYSIS:
        this.store.dispatch(new TestAnalysisActions.SetUpdateNeeded(updateNeeded));
        break;
      case RouteConstants.TEST_SPECIFICATIONS:
        this.store.dispatch(new TestSpecificationsActions.SetUpdateNeeded(updateNeeded));
        break;
    }
  }
  //Permission management for item linked to another
  canDeleteItem(item): void {
    this.parentItems = [];
    let modelId = "delete";
  
    if (item.lockedBy === '' || item.lockedBy.toLowerCase() === this.username.toLowerCase()) {
      this.itemsService.getParentItems(item.uid).subscribe({
        next: (result) => {
          this.parentItems = result;
          if (this.parentItems.length > 0) {
            modelId += "Linked" + item.uid;
            this.showModal(modelId);
          } else {
            this.canDeleteSwitch(item, modelId);
          }
        },
        error: (error) => {
          console.error("Error fetching parent items:", error);
        }
      });
    }
    else if (item.lockedBy  != '' && item.lockedBy != this.username){
      modelId += "Locked" + item.uid;
      this.showModal(modelId);
    }
  }
  

  //Delete switch. Can result in confirmation message or unable to delete item
  canDeleteSwitch(item: any, modelId: string){
    switch(this.path)
    {
      case RouteConstants.TEST_SUITES:
        this.testSuiteApiService.getByUid(item.uid).subscribe(response => {
          if (response.success) {
            this.selectedItem = response.returnValue as TestSuiteDto;
            this.permissionService.checkPermission(this.username, this.selectedItem.ownerIds).subscribe(hasPermission => {
            // this.permissionService.checkPermission(this.username, this.selectedItem.functionalGroupIds).subscribe(hasPermission => {
              modelId = this.modelIdCheck(hasPermission, modelId, item);
              this.showModal(modelId);
            })
          }
        })
        break;
      case RouteConstants.TEST_CASES:
        this.testCaseApiService.getByUid(item.uid).subscribe(response => {
          if (response.success) {
            this.selectedItem = response.returnValue as TestCaseDto;
            this.permissionService.checkPermission(this.username, this.selectedItem.ownerIds).subscribe(hasPermission => {
            // this.permissionService.checkPermission(this.username, this.selectedItem.functionalGroupIds).subscribe(hasPermission => {
              modelId = this.modelIdCheck(hasPermission, modelId, item);
              this.showModal(modelId);
            })
          }
        })
        break;
      case RouteConstants.TEST_ANALYSIS:
        this.testAnalysisApiService.getByUid(item.uid).subscribe(response => {
          if (response.success) {
            this.selectedItem = response.returnValue as TestAnalysisDto;
            this.permissionService.checkPermission(this.username, this.selectedItem.alternative.ownerIds, this.selectedItem.alternative.testedByGroupIds).subscribe(hasPermission => { 
            // this.permissionService.checkPermission(this.username, this.selectedItem.alternative.functionalGroupIds, this.selectedItem.alternative.testedByGroupIds).subscribe(hasPermission => { 
              modelId = this.modelIdCheck(hasPermission, modelId, item);
              this.showModal(modelId);
            })
          }
        })
        break;
      case RouteConstants.TEST_SPECIFICATIONS:
        this.testSpecificationApiService.getByUid(item.uid).subscribe(response => {
          if(response.success) {
            this.selectedItem = response.returnValue as TestSpecificationDto;
            this.permissionService.checkPermission(this.username, this.selectedItem.ownerIds).subscribe(hasPermission => {
            // this.permissionService.checkPermission(this.username, this.selectedItem.functionalGroupIds).subscribe(hasPermission => {
              modelId = this.modelIdCheck(hasPermission, modelId, item);
              this.showModal(modelId);
            })
          }
        })
        break;
      case RouteConstants.TEST_COVERAGE:
        if(this.permissionService.checkPermissionCreator(this.username, item.createdBy))modelId+= "Confirmation"+item.uid; 
        else modelId += "Unauthorized"+item.uid;
        this.showModal(modelId);
        break;
    }
  }

  deleteItem(item): void {
    let checkedOutItemUid = this.checkOutItemService.getCheckedOutItemUid();
    if(checkedOutItemUid && item.uid === checkedOutItemUid)
      this.unlockItem(item); 

    if(item.lockedBy !== '')
      return;

    switch(this.path)
    {
      case RouteConstants.TEST_SUITES:
        this.store.dispatch(new TestSuitesActions.DeleteTestSuite(item.uid, this.username));
        break;
      case RouteConstants.TEST_CASES:
        this.store.dispatch(new TestCasesActions.DeleteTestCase(item.uid, this.username));
        break;
      case RouteConstants.TEST_ANALYSIS:
        this.store.dispatch(new TestAnalysisActions.DeleteTestAnalysis(item.uid, this.username));
        break;
      case RouteConstants.TEST_SPECIFICATIONS:
        this.store.dispatch(new TestSpecificationsActions.DeleteTestSpecification(item.uid, this.username));
        break;
      case RouteConstants.TEST_COVERAGE:
        this.store.dispatch(new TestCoverageActions.DeleteTestCoverage(item.uid, this.username));
        break;
    }
  }

  getRoute(item: ItemType){
    return getRouteBase(item);
  }

  private modelIdCheck(hasPermission: boolean, modelId: string, item: any) {
      if (hasPermission)
        return modelId += "Confirmation" + item.uid;
      else
        return modelId += "Unauthorized" + item.uid;
  }

  private showModal(id: string) {
    var modal = document.getElementById(id);
    modal.classList.remove("hide");
    modal.classList.add("show");
  }

  public closeModal(id: string, uid: string) {
    var modal = document.getElementById(id + uid);
    modal.classList.remove("show");
    modal.classList.add("hide");
  }


  unlockItem(item): void {
    if (item.lockedBy != this.username)
      return;

    this.itemsService.unlock(item.uid).subscribe(() => {
        item.lockedBy = '';
        item.lockedTime = null;
    });
    this.checkOutItemService.set();
    this.setOrResetUpdateNeeded(true);
    this.getItems();
  }
}
