import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { OrganizationalGroup } from 'src/app/models/dto/organizationalGroup';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLOrganizationalGroupOutput } from 'src/app/graphQL/models/output/graphql-organizational-group-output';
import { GETALL_ORGANIZATIONAL_GROUPS_QUERY, GET_ORGANIZATIONAL_GROUP_BY_USERNAME_QUERY } from 'src/app/graphQL/queries/organizational-group-queries';
import { convertGraphqlOrganizationalGroupToDto } from 'src/app/graphQL/mappers/organizational-group.mappers';

@Injectable({
  providedIn: 'root',
})
export class OrganizationalGroupsApiService {

  constructor(private apolloClientService: ApolloClientService) { }

  get(): Observable<OrganizationalGroup[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllOrganizationalGroups: GraphQLOrganizationalGroupOutput[]}>({
          query: GETALL_ORGANIZATIONAL_GROUPS_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const organizationalGroups = results.data.configuratorAllOrganizationalGroups
        if (!organizationalGroups)
          return undefined
  
        return organizationalGroups.map(item => {
          return convertGraphqlOrganizationalGroupToDto(item);
        })
      })
    )
  }

  getByUsername(username: string): Observable<OrganizationalGroup> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorOrganizationalGroupByUsername: GraphQLOrganizationalGroupOutput}>({
          query: GET_ORGANIZATIONAL_GROUP_BY_USERNAME_QUERY,
          variables: {Username: username}
        })
      ),
      map(results => {
        const organizationalGroup = results.data.configuratorOrganizationalGroupByUsername
        if (!organizationalGroup)
          return undefined

        return convertGraphqlOrganizationalGroupToDto(organizationalGroup);
      })
    )
  }


}