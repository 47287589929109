import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { convertGraphqlFunctionalGroupToDto } from 'src/app/graphQL/mappers/functional-group.mappers';
import { GraphQLFunctionalGroupOutput } from 'src/app/graphQL/models/output/graphql-functional-group-output';
import { GETALL_FUNCTIONAL_GROUPS_QUERY, GET_FUNCTIONAL_GROUPS_BY_USERNAME_QUERY } from 'src/app/graphQL/queries/functional-group-queries';
import { FunctionalGroup } from 'src/app/models/dto/functionalGroup';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionalGroupsApiService {

  constructor(private apolloClientService: ApolloClientService) { }

  get(): Observable<FunctionalGroup[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllFunctionalGroups: GraphQLFunctionalGroupOutput[]}>({
          query: GETALL_FUNCTIONAL_GROUPS_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const functionalGroups = results.data.configuratorAllFunctionalGroups
        if (!functionalGroups)
          return undefined
  
        return functionalGroups.map(item => {
          return convertGraphqlFunctionalGroupToDto(item);
        })
      })
    )
  }

  getByUsername(username: string): Observable<FunctionalGroup[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorFunctionalGroupsByUsername: GraphQLFunctionalGroupOutput[]}>({
          query: GET_FUNCTIONAL_GROUPS_BY_USERNAME_QUERY,
          variables: {Username: username}
        })
      ),
      map(results => {
        const functionalGroups = results.data.configuratorFunctionalGroupsByUsername
        if (!functionalGroups)
          return undefined

        return functionalGroups.map(item => {
          return convertGraphqlFunctionalGroupToDto(item);
        })
      })
    )
  }
}
