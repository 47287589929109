import { Injectable } from '@angular/core';
import { OrganizationalGroupsActions } from 'src/app/store/organizationalGroups/organizationalGroups.actions';
import { OrganizationalGroupsState } from 'src/app/store/organizationalGroups/organizationalGroups.state';
import { OrganizationalGroup } from '../models/dto/organizationalGroup';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { FunctionalGroup } from '../models/dto/functionalGroup';
import { FunctionalGroupsActions } from '../store/functionalGroups/functionalGroups.actions';
import { FunctionalGroupsState } from '../store/functionalGroups/functionalGroups.state';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  loggedInOrganizationalGroup: OrganizationalGroup;
  // loggedInOrganizationalGroupIds: string[];
  constructor(private store: Store) { }

  // checkPermission(loggedInUsername : string, functionalGroupUIDs: string[], testedByGroupUIDs?: string[]): Observable<boolean>{
  //   this.store.dispatch(new FunctionalGroupsActions.SetFunctionalGroupsByUsername(loggedInUsername));

  //   return this.store.select<FunctionalGroup[]>(FunctionalGroupsState.getLoggedInFunctionalGroups).pipe(
  //     map(functionalGroups => {
  //         this.loggedInOrganizationalGroupIds = functionalGroups.map(group => group.uid);
  //         if(!testedByGroupUIDs)
  //tolower case check
  //           return functionalGroupUIDs.some(uid => this.loggedInOrganizationalGroupIds.includes(uid));
  //         else
  //           return this.loggedInOrganizationalGroupIds.some(uid =>
  //             functionalGroupUIDs.includes(uid) || testedByGroupUIDs.includes(uid)
  //           );
  //     })
  //   );
  // }
  
  checkPermission(loggedInUsername : string, ownerGroupUIDs: string[], testedByGroupUIDs?: string[]): Observable<boolean>{
    this.store.dispatch(new OrganizationalGroupsActions.SetOrganizationalGroupByUsername(loggedInUsername));

    return this.store.select<OrganizationalGroup>(OrganizationalGroupsState.getLoggedInOrganizationalGroup).pipe(
      map(orgGroup => {
          this.loggedInOrganizationalGroup = orgGroup;
          if(!testedByGroupUIDs)
            return ownerGroupUIDs.find(x => x.toUpperCase() == this.loggedInOrganizationalGroup.uid.toUpperCase()) != undefined;
          else
            return ownerGroupUIDs.find(x => x.toUpperCase() == this.loggedInOrganizationalGroup.uid.toUpperCase()) != undefined
              || testedByGroupUIDs.find(x => x.toUpperCase() == this.loggedInOrganizationalGroup.uid.toUpperCase()) != undefined;
      })
    );
  }

  checkPermissionCreator(loggedInUsername : string, createdBy: string):boolean{
    return loggedInUsername && createdBy ? loggedInUsername.toLowerCase() === createdBy.toLowerCase() : false;
  }
}
