import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { convertGraphqlBaseTestItemToDto, convertGraphqlFormFieldToDto, convertGraphqlResponseToDto, convertGraphqlResponseToDtoSimpleReturnValue } from 'src/app/graphQL/mappers/common.mappers';
import { convertTestCoverageDtoToGraphqlInput } from 'src/app/graphQL/mappers/test-coverage.mappers';
import { GraphQLBaseTestItemOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { GraphQLFormFieldOutput } from 'src/app/graphQL/models/output/graphql-form-field-output';
import { GraphQLResponseOutput } from 'src/app/graphQL/models/output/graphql-response-output';
import { CREATE_TEST_COVERAGE_MUTATION, DELETE_TEST_COVERAGE_MUTATION, UPDATE_TEST_COVERAGE_MUTATION } from 'src/app/graphQL/mutations/test-coverage-mutations';
import { GETALL_TEST_COVERAGE_QUERY, GET_TEST_OVERAGE_QUERY, GET_TEST_OVERAGE_UI_TEMPLATE_QUERY } from 'src/app/graphQL/queries/test-coverage-queries';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { ResponseDto } from 'src/app/models/dto/responseDto';
import { TestCoverageDto } from 'src/app/models/dto/testCoverageDto';
import { formField } from 'src/app/models/formField';
import { ApolloClientService } from './apollo-client.service';

@Injectable({
  providedIn: 'root',
})
export class TestCoverageApiService {

  constructor(private apolloClientService: ApolloClientService) {}
  
  getAll(regBy: string): Observable<BaseTestItemDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllTestCoverages : GraphQLBaseTestItemOutput[]}>({
          query: GETALL_TEST_COVERAGE_QUERY,
          variables: {RegBy: regBy}
        })
      ),
      map(results => {
        const testCoverages = results.data.configuratorAllTestCoverages
        if (!testCoverages)
          return undefined

        return testCoverages.map(item => {
          return convertGraphqlBaseTestItemToDto(item);
        })
      })
    )
  }

  getByUid(uid: string): Observable<ResponseDto> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestCoverageById: GraphQLResponseOutput}>({
          query: GET_TEST_OVERAGE_QUERY,
          variables: {Uid: uid}
        })
      ),
      map(results => {
        const testCoverage = results.data.configuratorTestCoverageById
        if (!testCoverage)
          return undefined

        return convertGraphqlResponseToDto<TestCoverageDto>(testCoverage);
      })
    )
  }

  getUiTemplate(): Observable<formField[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestCoverageUiTemplate: GraphQLFormFieldOutput[]}>({
          query: GET_TEST_OVERAGE_UI_TEMPLATE_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const testCoverageUiTemplate = results.data.configuratorTestCoverageUiTemplate
        if (!testCoverageUiTemplate) 
          return undefined

        return testCoverageUiTemplate.map(item => {
          return convertGraphqlFormFieldToDto(item);
        })
      })
    )
  }

  create(testCoverage: any): Observable<ResponseDto> {
    const graphqlTestCoverageInput = convertTestCoverageDtoToGraphqlInput(testCoverage)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateTestCoverage: GraphQLResponseOutput}>({
          mutation: CREATE_TEST_COVERAGE_MUTATION,
          variables: {TestCoverage: graphqlTestCoverageInput }
        })
      ),
      map(result => {
        const configuratorCreateTestCoverage = result.data.configuratorCreateTestCoverage
        if (!configuratorCreateTestCoverage) 
          return undefined
        return convertGraphqlResponseToDtoSimpleReturnValue(configuratorCreateTestCoverage as GraphQLResponseOutput)
      })
    )
  }

  update(testCoverage: any): Observable<ResponseDto> {
    const graphqlTestCoverageInput = convertTestCoverageDtoToGraphqlInput(testCoverage)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorUpdateTestCoverage: GraphQLResponseOutput}>({
          mutation: UPDATE_TEST_COVERAGE_MUTATION,
          variables: {TestCoverage: graphqlTestCoverageInput }
        })
      ),
      map(result =>{
        const configuratorCreateTestCoverage = result.data.configuratorUpdateTestCoverage
        if (!configuratorCreateTestCoverage) 
          return undefined
        return convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorUpdateTestCoverage as GraphQLResponseOutput)
      } )
    )
  }

  delete(uid: string, regBy: string): Observable<any> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate({
          mutation: DELETE_TEST_COVERAGE_MUTATION,
          variables: {Uid: uid}
        })
      ),
      map(result =>(result.data))
    )
  }
}
