import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResponseDto } from 'src/app/models/dto/responseDto';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestCaseDto } from 'src/app/models/dto/testCaseDto';
import { formField } from 'src/app/models/formField';;
import { GETALL_TEST_CASES_QUERY, GET_TEST_CASE_QUERY, GET_TEST_CASE_UI_TEMPLATE_QUERY } from 'src/app/graphQL/queries/test-case-queries';
import { ApolloClientService } from './apollo-client.service';
import { GraphQLResponseOutput } from 'src/app/graphQL/models/output/graphql-response-output';
import { GraphQLFormFieldOutput } from 'src/app/graphQL/models/output/graphql-form-field-output';
import { convertGraphqlBaseTestItemToDto, convertGraphqlFormFieldToDto, convertGraphqlResponseToDto, convertGraphqlResponseToDtoSimpleReturnValue } from 'src/app/graphQL/mappers/common.mappers';
import { CREATE_TEST_CASE_MUTATION, DELETE_TEST_CASE_MUTATION, UPDATE_TEST_CASE_MUTATION } from 'src/app/graphQL/mutations/test-case-mutations';
import { GraphQLBaseTestItemOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { convertTestCaseDtoToGraphqlInput } from 'src/app/graphQL/mappers/test-case.mappers';

@Injectable({
  providedIn: 'root'
})
export class TestCaseApiService {

  constructor(private apolloClientService: ApolloClientService) { }

  getAll(company: string, regBy: string, functionalGroupId?: string): Observable<BaseTestItemDto[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorAllTestCases : GraphQLBaseTestItemOutput[]}>({
          query: GETALL_TEST_CASES_QUERY,
          variables: {Company: company, RegBy: regBy, OrgGroup: functionalGroupId}
        })
      ),
      map(results => {
        const testCases = results.data.configuratorAllTestCases
        if (!testCases) 
          return undefined

        return testCases.map(item => {
          return convertGraphqlBaseTestItemToDto(item);
        })
      })
    )
  }

  // getAllFGOG(company: string, regBy?: string, functionalGroupId?: string ): Observable<BaseTestItemDto[]> {
  //   return this.apolloClientService.apolloClient$.pipe(
  //     switchMap(client => 
  //       client.query<{configuratorAllTestCases : GraphQLBaseTestItemOutput[]}>({
  //         query: GETALL_TEST_CASES_QUERY,
  //         variables: {RegBy: regBy, OrgGroup: functionalGroupId, Company: company}
  //       })
  //     ),
  //     map(results => {
  //       const testCases = results.data.configuratorAllTestCases
  //       if (!testCases) 
  //         return undefined

  //       return testCases.map(item => {
  //         return convertGraphqlBaseTestItemToDto(item);
  //       })
  //     })
  //   )
  // }

  getByUid(uid: string): Observable<ResponseDto> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestCaseById: GraphQLResponseOutput}>({
          query: GET_TEST_CASE_QUERY,
          variables: {Uid: uid}
        })
      ),
      map(results => {
        const testCase = results.data.configuratorTestCaseById
        if (!testCase)
          return undefined

        return convertGraphqlResponseToDto<TestCaseDto>(testCase);
      })
    )
  }

  getUiTemplate(): Observable<formField[]> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.query<{configuratorTestCaseUiTemplate: GraphQLFormFieldOutput[]}>({
          query: GET_TEST_CASE_UI_TEMPLATE_QUERY,
          variables: {}
        })
      ),
      map(results => {
        const testCaseUiTemplate = results.data.configuratorTestCaseUiTemplate
        if (!testCaseUiTemplate) 
          return undefined

        return testCaseUiTemplate.map(item => {
          return convertGraphqlFormFieldToDto(item);
        })
      })
    )
  }

  create(testCase: TestCaseDto): Observable<ResponseDto> {
    const graphqlTestCaseInput = convertTestCaseDtoToGraphqlInput(testCase)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorCreateTestCase: GraphQLResponseOutput}>({
          mutation: CREATE_TEST_CASE_MUTATION,
          variables: {TestCase: graphqlTestCaseInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorCreateTestCase as GraphQLResponseOutput))
    )
  }

  update(testCase: TestCaseDto): Observable<ResponseDto> {
    const graphqlTestCaseInput = convertTestCaseDtoToGraphqlInput(testCase)
      
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate<{configuratorUpdateTestCase: GraphQLResponseOutput}>({
          mutation: UPDATE_TEST_CASE_MUTATION,
          variables: {TestCase: graphqlTestCaseInput }
        })
      ),
      map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorUpdateTestCase as GraphQLResponseOutput))
    )
  }

  delete(uid: string, regBy: string): Observable<any> {
    return this.apolloClientService.apolloClient$.pipe(
      switchMap(client => 
        client.mutate({
          mutation: DELETE_TEST_CASE_MUTATION,
          variables: {Uid: uid}
        })
      ),
      map(result =>(result.data))
    )
  }
}
