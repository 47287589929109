import { RequirementDto } from 'src/app/models/dto/polarion/requirementDto';
import { RequirementEvaluation } from 'src/app/models/requirementEvaluation';

export class RequirementMapper {

  public static mapEvaluation(items: RequirementDto[]): RequirementEvaluation[] {
    let results: RequirementEvaluation[] = [];

    if (!items || items.length === 0) return results;

    items.forEach(item => {
      let link = this.mapOne(item);
      if (link) 
        results.push(link)
    })

    return results;
  }

  public static mapOne(dto: RequirementDto): RequirementEvaluation {
    if(!dto) return undefined;

    let item: RequirementEvaluation = {
      polarionId: dto.polarionId,
      title: dto.title,
      irmaVersion: dto.irmaVersion,
      revision:  dto.revision,
      asil:  dto.asil,
      status:  dto.status,
      legislation:  dto.legislation,
      testAnalysis: []
    };
    return item;
  }
}
