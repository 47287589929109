import { gql } from "apollo-angular";

export const GETALL_TEST_CASES_QUERY = gql`query configuratorAllTestCases($Company: String, $RegBy: String, $OrgGroup: String) {
    configuratorAllTestCases(Company: $Company, RegBy: $RegBy, OrgGroup: $OrgGroup) {
        Version
        Uid
        ItemNo
        Name
        ModifiedBy
        ModificationTime
        LockedTime
        LockedBy
        CreationTime
        CreatedBy
        Comment
        ApprovedForTestLevel
        ApprovalStatus
    }
  }
  `
  

export const GET_TEST_CASE_QUERY = gql`query configuratorTestCaseById($Uid: String!) {
    configuratorTestCaseById(Uid: $Uid) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `
    

export const GET_TEST_CASE_UI_TEMPLATE_QUERY = gql`query configuratorTestCaseUiTemplate {
    configuratorTestCaseUiTemplate {
        Name
        Category
        DefaultValue
        FieldType
        IsMultiValue
        HasHierarchyValues
        IsReadOnly
        Label
        IsRequired
        Order
        Tooltip
        ValueList
    }
  }
  `
