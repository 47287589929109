import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FunctionalGroupsApiService } from "src/app/services/api/functional-groups-api.service";
import { FunctionalGroupsActions } from "./functionalGroups.actions";
import { FunctionalGroupsStateModel } from "./functionalGroups.state.model";

@State<FunctionalGroupsStateModel>({
    name: 'functionalGroups',
    defaults: {
        functionalGroups: [],
        loggedInFunctionalGroups: [],
    },
  })
@Injectable()
export class FunctionalGroupsState {
    constructor(private functionalGroupsApiService: FunctionalGroupsApiService) {}

    @Action(FunctionalGroupsActions.SetAllFunctionalGroups)
    SetAllFunctionalGroups(ctx: StateContext<FunctionalGroupsStateModel>) {
      const state = ctx.getState();
      //don't update state if we have already downloaded items
      // if (state.functionalGroups !== undefined && state.functionalGroups.length > 0)
      //   return;
  
      // this.functionalGroupsApiService.get().subscribe((functionalGroups) => {
      //   ctx.patchState({ functionalGroups: functionalGroups });
      // });
    }
  
    @Action(FunctionalGroupsActions.SetFunctionalGroupsByUsername)
    SetFunctionalGroupsByUsername(
      ctx: StateContext<FunctionalGroupsStateModel>,
      action: FunctionalGroupsActions.SetFunctionalGroupsByUsername
    ) {
      const state = ctx.getState();
      //don't update state if we have already downloaded items
      if (state.loggedInFunctionalGroups !== undefined && state.loggedInFunctionalGroups.length > 0) return;
  
      this.functionalGroupsApiService.getByUsername(action.username).subscribe((functionalGroups) => {
        ctx.patchState({ loggedInFunctionalGroups: functionalGroups });
      });
    }
      
    @Selector()
    static getAllFunctionalGroups(state: FunctionalGroupsStateModel) {
      return state.functionalGroups;
    }
  
    @Selector()
    static getLoggedInFunctionalGroups(state: FunctionalGroupsStateModel) {
      return state.loggedInFunctionalGroups;
    }

}