import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { Subject } from 'rxjs';
import { ApprovalStatus } from 'src/app/models/enums/approvalStatus';
import { SpecificationEvaluation } from 'src/app/models/specificationEvaluation';

@Component({
  selector: 'app-evaluation-statistics',
  templateUrl: './evaluation-statistics.component.html',
  styleUrls: ['./evaluation-statistics.component.scss']
})
export class EvaluationStatisticsComponent implements OnInit {
  @Input() specificationEvaluation: SpecificationEvaluation;
  
  readonly passedEvaluationTitle = 'Requirements passed evaluation';
  readonly failedEvaluationTitle = 'Requirements failed evaluation';
  readonly approvedTitle = 'Approved TestAnalysis';
  readonly notApprovedTitle = 'Not approved TestAnalysis';
  readonly EvaluationRuleMessage = `Evaluation is passed if:
                                    - requirement has linked at least one TestAnalysis
                                    - and all TestAnalyses are approved`;
  evaluationReportData = [ 
          { name: this.passedEvaluationTitle, value: 0},
          { name: this.failedEvaluationTitle, value: 0 }
        ];
  approvalReportData= [ 
          { name: this.approvedTitle, value: 0 },
          { name: this.notApprovedTitle, value: 0 }
        ];
  colorScheme: Color = {
    name: 'myScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#438151', '#950A16', '#F58E90']
  };

  legendPosition: LegendPosition = LegendPosition.Below;


  update$: Subject<any> = new Subject();

  constructor() { }

  ngOnInit(): void {
    this.populateCharts();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.populateCharts();
  }

  populateCharts() {
    this.evaluationReportData = this.generateEvaluationChart();
    this.approvalReportData = this.generateApprovalsChart();
  }

  generateEvaluationChart()
  {
    let data = [ 
      { name: this.passedEvaluationTitle, value: 0},
      { name: this.failedEvaluationTitle, value: 0 }
    ];

    this.specificationEvaluation.requirements.forEach(requirementEvaluation => {
      this.updateEvaluationsChart(requirementEvaluation.evaluationPassed, data);
    });

    return data;
  }

  generateApprovalsChart()
  {
    let data = [ 
      { name: this.approvedTitle, value: 0},
      { name: this.notApprovedTitle, value: 0 }
    ];

    this.specificationEvaluation.requirements.forEach(requirementEvaluation => {
      requirementEvaluation.testAnalysis.forEach(testAnalyze => {
        this.updateApprovalsChart(testAnalyze.approvalStatus, data)
      });
    });

    return data;
  }

  updateEvaluationsChart(evaluationPassed: boolean, chartData : any) {
    if(evaluationPassed === true)
      chartData.find(x => x.name === this.passedEvaluationTitle).value++;
    else
      chartData.find(x => x.name === this.failedEvaluationTitle).value++;
  }

  updateApprovalsChart(approvalStatus: ApprovalStatus, chartData: any) {
    if(approvalStatus === ApprovalStatus.Approved)
      chartData.find(x => x.name === this.approvedTitle).value++;
    else
      chartData.find(x => x.name === this.notApprovedTitle).value++;
  }
}
