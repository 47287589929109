import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestAnalysisActions } from './testAnalysis.actions';
import { TestAnalysisStateModel } from './testAnalysis.state.model';
import { patch, removeItem } from '@ngxs/store/operators';
import { TestAnalysisApiService } from 'src/app/services/api/test-analysis-api.service';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';

@State<TestAnalysisStateModel>({
  name: 'TestAnalysis',
  defaults: {
    TestAnalysis: [],
    orgGroupTestAnalysis: [],
    functionalGroupTestAnalysis: [],
    myTestAnalysis: [],
    updateNeeded : false
  },
})
@Injectable()
export class TestAnalysisState {
  constructor(/*private itemsService: ItemsService, */private testAnalysisApiService: TestAnalysisApiService) {}

  @Action(TestAnalysisActions.SetAllTestAnalysis)
  SetAllTestAnalysis(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.SetAllTestAnalysis) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.TestAnalysis !== undefined && state.TestAnalysis.length > 0) return;

    this.testAnalysisApiService.getAll(action.company, '', '').subscribe((TestAnalysis) => {
      ctx.patchState({ TestAnalysis: TestAnalysis });
    });
  }

  @Action(TestAnalysisActions.SetOrgGroupTestAnalysis)
  SetOrgGroupTestAnalysis(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.SetOrgGroupTestAnalysis) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.orgGroupTestAnalysis !== undefined && state.orgGroupTestAnalysis.length > 0) 
      return;

    this.testAnalysisApiService.getAll('', '', action.orgGroupId )
      .subscribe(result =>{
        ctx.patchState({ orgGroupTestAnalysis: result });
      });
  }
  
  @Action(TestAnalysisActions.SetMyTestAnalysis)
  SetMyTestAnalysis(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.SetMyTestAnalysis) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.myTestAnalysis !== undefined && state.myTestAnalysis.length > 0) 
      return;

    this.testAnalysisApiService.getAll('', action.userId, '')
      .subscribe(result =>{
        ctx.patchState({ myTestAnalysis: result });
      });
  }

  @Action(TestAnalysisActions.SetOrgGroupTestAnalysis)
  SetFunctionalGroupTestAnalysis(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.SetFunctionalGroupTestAnalysis) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    // if (!state.updateNeeded && state.functionalGroupTestAnalysis !== undefined && state.functionalGroupTestAnalysis.length > 0) 
    //   return;

    // for (let index = 0; index < action.functionalGroupIds.length; index++) {
    //   const fg = action.functionalGroupIds[index];
    //   this.testAnalysisApiService.getAll('', fg ).subscribe(result =>{
    //     if(result && result.length > 0) {
    //       ctx.patchState({ functionalGroupTestAnalysis: result });
    //       return;
    //     }
    //   });
    // }
  }

  @Action(TestAnalysisActions.DeleteTestAnalysis)
  DeleteTestAnalysis(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.DeleteTestAnalysis){

    this.testAnalysisApiService.delete(action.testAnalysisUid, action.regBy)
      .subscribe(result =>{
        ctx.setState(
          patch<TestAnalysisStateModel>({
            orgGroupTestAnalysis: removeItem<BaseTestItemDto>(x => x.uid === action.testAnalysisUid),
            TestAnalysis: removeItem<BaseTestItemDto>(x => x.uid === action.testAnalysisUid)
          })
        )
      });
  }

  @Action(TestAnalysisActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestAnalysisStateModel>, action: TestAnalysisActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }

  @Selector()
  static getAllTestAnalysis(state: TestAnalysisStateModel) {
    return state.TestAnalysis;
  }

  @Selector()
  static getOrgGroupTestAnalysis(state: TestAnalysisStateModel) {
    return state.orgGroupTestAnalysis;
  }
  @Selector()
  static getMyTestAnalysis(state: TestAnalysisStateModel) {
    return state.myTestAnalysis;
  }

  @Selector()
  static getFgTestAnalysis(state: TestAnalysisStateModel) {
    return state.functionalGroupTestAnalysis;
  }
}
