import { gql } from "apollo-angular";

export const GETALL_FUNCTIONAL_GROUPS_QUERY = gql`query configuratorAllFunctionalGroups {
  configuratorAllFunctionalGroups {
        Uid
        Name
        Company
    }
  }
  `

export const GET_FUNCTIONAL_GROUPS_BY_USERNAME_QUERY = gql`query configuratorFunctionalGroupsByUsername($Username: String!) {
  configuratorFunctionalGroupsByUsername(Username: $Username) {
        Uid
        Name
        Company
    }
  }
  `