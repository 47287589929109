import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { TestSpecificationStateModel } from './testSpecifications.state.model';
import { TestSpecificationApiService } from 'src/app/services/api/test-specification-api.service';
import { TestSpecificationsActions } from './testSpecifications.actions';

@State<TestSpecificationStateModel>({
  name: 'testSpecifications',
  defaults: {
    testSpecifications: [],
    orgGroupTestSpecifications: [],
    functionalGroupTestSpecifications: [],
    myTestSpecifications: [],
    updateNeeded : false
  },
})
@Injectable()
export class TestSpecificationsState {
  //pageNumber = 1;
  //pageSize = 10;
  constructor(private testSpecificationApiService: TestSpecificationApiService) {}

  @Action(TestSpecificationsActions.SetAllTestSpecifications)
  SetAllTestSpecifications(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.SetAllTestSpecifications) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.testSpecifications !== undefined && state.testSpecifications.length > 0) 
      return;

    this.testSpecificationApiService.getAll(action.company, '', '').subscribe(testSpecifications =>{
      ctx.patchState({ testSpecifications: testSpecifications });
    });
  }

  @Action(TestSpecificationsActions.SetOrgGroupTestSpecifications)
  SetOrgGroupTestSpecifications(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.SetOrgGroupTestSpecifications) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.orgGroupTestSpecifications !== undefined && state.orgGroupTestSpecifications.length > 0) 
      return;

    this.testSpecificationApiService.getAll('', '', action.orgGroupId ).subscribe(testSpecifications =>{
        ctx.patchState({ orgGroupTestSpecifications: testSpecifications });
    });
  }
  
  @Action(TestSpecificationsActions.SetMyTestSpecifications)
  SetMyTestSpecifications(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.SetMyTestSpecifications) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.myTestSpecifications !== undefined && state.myTestSpecifications.length > 0) 
      return;

    this.testSpecificationApiService.getAll('', action.userId, '' ).subscribe(testSpecifications =>{
        ctx.patchState({ myTestSpecifications: testSpecifications });
    });
  }
  
  @Action(TestSpecificationsActions.SetFunctionalGroupTestSpecifications)
  SetFunctionalGroupTestSpecifications(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.SetFunctionalGroupTestSpecifications) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.functionalGroupTestSpecifications !== undefined && state.functionalGroupTestSpecifications.length > 0) 
      return;

    for (let index = 0; index < action.functionalGroupIds.length; index++) {
      const fg = action.functionalGroupIds[index];
      this.testSpecificationApiService.getAll('', fg ).subscribe(result =>{
        if(result && result.length > 0) {
          ctx.patchState({ functionalGroupTestSpecifications: result });
          return;
        }
      });
    }
  }

  @Action(TestSpecificationsActions.DeleteTestSpecification)
  DeleteTestSpecification(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.DeleteTestSpecification){

    this.testSpecificationApiService.delete(action.testSpecificationUid, action.regBy)
      .subscribe(result =>{
        ctx.setState(
          patch<TestSpecificationStateModel>({
            orgGroupTestSpecifications: removeItem<BaseTestItemDto>(x => x.uid === action.testSpecificationUid),
            testSpecifications: removeItem<BaseTestItemDto>(x => x.uid === action.testSpecificationUid)
          })
        )
      });
  }

  @Action(TestSpecificationsActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestSpecificationStateModel>, action: TestSpecificationsActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }

  @Selector()
  static getAllTestSpecifications(state: TestSpecificationStateModel) {
    return state.testSpecifications;
  }

  @Selector()
  static getOrgGroupTestSpecifications(state: TestSpecificationStateModel) {
    return state.orgGroupTestSpecifications;
  }

  @Selector()
  static getMyTestSpecifications(state: TestSpecificationStateModel) {
    return state.myTestSpecifications;
  }

  @Selector()
  static getFgTestSpecifications(state: TestSpecificationStateModel) {
    return state.functionalGroupTestSpecifications;
  }
}
