import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TestCasesActions } from './testCases.actions';
import { TestCasesStateModel } from './testCases.state.model';
import { patch, removeItem } from '@ngxs/store/operators';
import { TestCaseApiService } from 'src/app/services/api/test-case-api.service';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';

@State<TestCasesStateModel>({
  name: 'testCases',
  defaults: {
    testCases: [],
    orgGroupTestCases: [],
    functionalGroupTestCases: [],
    myTestCases: [],
    updateNeeded : false
  },
})
@Injectable()
export class TestCasesState {
  constructor(private testCaseApiService: TestCaseApiService) {}

  @Action(TestCasesActions.SetAllTestCases)
  SetAllTestCases(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetAllTestCases) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.testCases !== undefined && state.testCases.length > 0) return;

    this.testCaseApiService.getAll(action.company, '', '').subscribe((testCases) => {
      ctx.patchState({ testCases: testCases });
    });
  }

  //Delete after FG merge
  @Action(TestCasesActions.SetOrgGroupTestCases)
  SetOrgGroupTestCases(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetOrgGroupTestCases) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.orgGroupTestCases !== undefined && state.orgGroupTestCases.length > 0) 
      return;

    this.testCaseApiService.getAll('', '', action.orgGroupId )
      .subscribe(result =>{
        ctx.patchState({ orgGroupTestCases: result });
      });
  }

  @Action(TestCasesActions.SetMyTestCases)
  SetMyTestCases(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetMyTestCases) {
    const state = ctx.getState();
    //don't update state if we have already downloaded items
    if (!state.updateNeeded && state.myTestCases !== undefined && state.myTestCases.length > 0) 
      return;

    this.testCaseApiService.getAll('', action.userId, '' )
      .subscribe(result =>{
        ctx.patchState({ myTestCases: result });
      });
  }
  

  @Action(TestCasesActions.SetFunctionalGroupTestCases)
  SetFunctionalGroupTestCases(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetFunctionalGroupTestCases) {
    const state = ctx.getState();
    // //don't update state if we have already downloaded items
    // if (!state.updateNeeded && state.functionalGroupTestCases !== undefined && state.functionalGroupTestCases.length > 0) 
    //   return;

    // for (let index = 0; index < action.functionalGroupIds.length; index++) {
    //   const fg = action.functionalGroupIds[index];
    //   this.testCaseApiService.getAll('', fg ).subscribe(result =>{
    //     if(result && result.length > 0) {
    //       ctx.patchState({ functionalGroupTestCases: result });//double check this. Must append for all, currently does not
    //       return;
    //     }
    //   });
    // }
  }

  @Action(TestCasesActions.DeleteTestCase)
  DeleteTestCase(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.DeleteTestCase){

    this.testCaseApiService.delete(action.testCaseUid, action.regBy)
      .subscribe(result =>{

        ctx.setState(
          patch<TestCasesStateModel>({
            orgGroupTestCases: removeItem<BaseTestItemDto>(x => x.uid === action.testCaseUid),
            testCases: removeItem<BaseTestItemDto>(x => x.uid === action.testCaseUid)
          })
        )
      });
  }
  
  @Action(TestCasesActions.SetUpdateNeeded)
  setUpdateNeeded(ctx: StateContext<TestCasesStateModel>, action: TestCasesActions.SetUpdateNeeded) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updateNeeded: action.updateNeeded
    });
  }

  @Selector()
  static getAllTestCases(state: TestCasesStateModel) {
    return state.testCases;
  }

  @Selector()
  static getOrgGroupTestCases(state: TestCasesStateModel) {
    return state.orgGroupTestCases;
  }
  @Selector()
  static getMyTestCases(state: TestCasesStateModel) {
    return state.myTestCases;
  }
  @Selector()
  static getFgTestCases(state: TestCasesStateModel) {
    return state.functionalGroupTestCases;
  }
}
