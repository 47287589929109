
<p-toast></p-toast>

<div *ngIf="!locked" style="display: flex" [formGroup]="formCopy">
  <div class="input-field">
    <sdds-textfield ngDefaultControl
      type="text"
      [formControlName]="field.name"
      [id]="field.name"
      [placeholder]="field.label"
      [pTooltip]="'e.g. ID-123456 or 123456'">
      <span slot="sdds-label">{{ field.label }}</span>
    </sdds-textfield>
    <div *ngIf="formCopy.controls[field.name].invalid && formCopy.controls[field.name].touched"
      class="sdds-banner sdds-banner-error">
      <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
      <h6 class="sdds-banner-header">Invalid ID format</h6>
    </div>
    <!--If speciciation does not exist-->
    <div class="sdds-banner sdds-banner-error" *ngIf="errorMessageShown">
      <i class="pi pi-info-circle sdds-banner-prefix" style="font-size: initial"></i>
      <h6 class="sdds-banner-header">{{errorMessage}}</h6>
    </div>
  </div>
  <div class="input-field" style="padding-left: 5rem;">
    <sdds-textfield
      ngDefaultControl
      type="number"
      formControlName="irmaVersion"
      id="irmaVersion"
      [placeholder]="'IRMA version'">
      <span slot="sdds-label">IRMA version</span>
    </sdds-textfield>
  </div>
  <div>
    <button
      type="button"
      class="sdds-btn sdds-btn-secondary sdds-btn-sm specificationButton"
      (click)="addSpecification()">
      <i class="pi pi-plus" style="font-size: initial"></i>
    </button>
  </div>
  <div>
    <i *ngIf="field.name == formControlConstants.TASpecification" 
    [class]="'pi pi-info-circle'" style="font-size: initial" 
    pTooltip="Add specification and link a requirement to it" [escape]="false"></i>
  </div>
</div>

<div *ngIf="items  && items.length > 0">
  <p-table [value]="items" dataKey="polarionId">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 45%; text-align: left;">Polarion Specification</th>
        <th style="width: 5%;">IRMA Version</th>
        <th style="width: 5%;">ASIL</th>
        <th style="width: 10%;">Status</th>
        <th style="width: 10%;">Maturity status</th>
        <th style="width: 10%;" *ngIf="field.name == formControlConstants.TASpecification">Connected Requirement</th>
        <th style="width: 15%;">Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td style="text-align: left;">
          <a [href]="specificationPolarionUrl.replace('{0}', item.polarionId).replace('{1}', item.revision)">{{ item.polarionId }} - {{ item.title }}</a></td>
        <td>{{ item.irmaVersion }}</td>
        <td>{{ item.asil}}</td> 
        <td>{{ item.status}}</td> 
        <td>{{ item.maturityStatus}}</td> 
        <td *ngIf="field.name == formControlConstants.TASpecification">{{ item.linkedRequirement?.polarionId }}</td>
        <td>
          <button
            type="button"
            *ngIf="field.name == formControlConstants.TestCoverageSpecification"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm" style="margin-right: 2rem;"
            (click)="specificationSelectedFromTable(item)"
            pTooltip="Evaluate specification" tooltipPosition="bottom">
            <i class="pi pi-caret-right" style="font-size: initial"></i>
        </button>

          <a *ngIf="field.name == formControlConstants.TestCoverageSpecification" href="{{generateTraceabilityReportUrl}}{{item.polarionId}}">
            <button
              type="button"
              class="sdds-btn sdds-btn-secondary sdds-btn-sm"
              pTooltip="Get traceability report"
              tooltipPosition="bottom"
              (click)="showGeneratingReportMessage(item.polarionId)">
              <i class="pi pi-file-o" style="font-size: initial"></i>
            </button>
          </a>

          <button
            type="button"
            *ngIf="field.name == formControlConstants.TASpecification && !locked"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm" style="margin-right: 5rem;"
            (click)="linkConnectedRequirements(item)"
            pTooltip="Edit connected requirement">
            <i class="pi pi-pencil" style="font-size: initial"></i>
          </button>

            <button
            type="button"
            *ngIf="!locked"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm removeItem"
            (click)="removeItem(item)">
            <i class="pi pi-trash" style="font-size: initial"></i>
          </button>
        </td>
      </tr>
    </ng-template>

  </p-table>

</div>
<sdds-modal size="md" id="connected-items-modal-spec">
  <h5 slot="sdds-modal-headline">Link connected Polarion Requirement</h5>
  <div slot="sdds-modal-body">
    <app-connected-polarion-items [mainPolarionItem]="addedSpecification" [connectedPolarionItems]="connectedRequirements" 
    [isRequirement]="false" (itemsSelectedEvent)="addConnectedRequirement($event)"></app-connected-polarion-items>
  </div>
</sdds-modal>
