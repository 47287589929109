import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResponseDto } from 'src/app/models/dto/responseDto';
import { BaseTestItemDto } from 'src/app/models/dto/Base-Test-item-dto';
import { formField } from 'src/app/models/formField';
import { TestSpecificationDto } from 'src/app/models/dto/testSpecificationDto';
import { ApolloClientService } from './apollo-client.service';
import { GETALL_TEST_SPECIFICATIONS_QUERY, GET_TEST_SPECIFICATION_QUERY, GET_TEST_SPECIFICATION_UI_TEMPLATE_QUERY } from 'src/app/graphQL/queries/test-specification-queries';
import { GraphQLBaseTestItemOutput } from 'src/app/graphQL/models/output/graphql-base-test-item-output';
import { convertGraphqlBaseTestItemToDto, convertGraphqlFormFieldToDto, convertGraphqlResponseToDto, convertGraphqlResponseToDtoSimpleReturnValue } from 'src/app/graphQL/mappers/common.mappers';
import { GraphQLResponseOutput } from 'src/app/graphQL/models/output/graphql-response-output';
import { GraphQLFormFieldOutput } from 'src/app/graphQL/models/output/graphql-form-field-output';
import { convertTestSpecificationDtoToGraphqlInput } from 'src/app/graphQL/mappers/test-specification.mapper';
import { CREATE_TEST_SPECIFICATION_MUTATION, DELETE_TEST_SPECIFICATION_MUTATION, UPDATE_TEST_SPECIFICATION_MUTATION } from 'src/app/graphQL/mutations/test-specification-mutations';

@Injectable({
  providedIn: 'root'
})
export class TestSpecificationApiService {

  constructor(private apolloClientService: ApolloClientService) { }

getAll(company: string, regBy: string, functionalGroupId?: string): Observable<BaseTestItemDto[]> {
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.query<{configuratorAllTestSpecifications : GraphQLBaseTestItemOutput[]}>({
        query: GETALL_TEST_SPECIFICATIONS_QUERY,
        variables: {Company: company, RegBy: regBy, OrgGroup: functionalGroupId}
      })
    ),
    map(results => {
      const testSpecifications = results.data.configuratorAllTestSpecifications
      if (!testSpecifications)
        return undefined

      return testSpecifications.map(item => {
        return convertGraphqlBaseTestItemToDto(item);
      })
    })
  )
}

getByUid(uid: string): Observable<ResponseDto> {
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.query<{configuratorTestSpecificationById: GraphQLResponseOutput}>({
        query: GET_TEST_SPECIFICATION_QUERY,
        variables: {Uid: uid}
      })
    ),
    map(results => {
      const testSpecification = results.data.configuratorTestSpecificationById
      if (!testSpecification) 
        return undefined

      return convertGraphqlResponseToDto<TestSpecificationDto>(testSpecification);
    })
  )
}

getUiTemplate(): Observable<formField[]> {
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.query<{configuratorTestSpecificationUiTemplate: GraphQLFormFieldOutput[]}>({
        query: GET_TEST_SPECIFICATION_UI_TEMPLATE_QUERY,
        variables: {}
      })
    ),
    map(results => {
      const testSpecificationUiTemplate = results.data.configuratorTestSpecificationUiTemplate
      if (!testSpecificationUiTemplate)
        return undefined

      return testSpecificationUiTemplate.map(item => {
        return convertGraphqlFormFieldToDto(item);
      })
    })
  )
}


create(testSpecification: TestSpecificationDto): Observable<ResponseDto> {
  const graphqlTestSpecificationInput = convertTestSpecificationDtoToGraphqlInput(testSpecification)
    
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.mutate<{configuratorCreateTestSpecification: GraphQLResponseOutput}>({
        mutation: CREATE_TEST_SPECIFICATION_MUTATION,
        variables: {TestSpecification: graphqlTestSpecificationInput }
      })
    ),
    map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorCreateTestSpecification as GraphQLResponseOutput))
  )
}

update(testSpecification: TestSpecificationDto): Observable<ResponseDto> {
  const graphqlTestSuiteInput = convertTestSpecificationDtoToGraphqlInput(testSpecification)
    
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.mutate<{configuratorUpdateTestSpecification: GraphQLResponseOutput}>({
        mutation: UPDATE_TEST_SPECIFICATION_MUTATION,
        variables: {TestSpecification: graphqlTestSuiteInput }
      })
    ),
    map(result => convertGraphqlResponseToDtoSimpleReturnValue(result.data.configuratorUpdateTestSpecification as GraphQLResponseOutput))
  )
}

delete(uid: string, regBy: string): Observable<any> {
  return this.apolloClientService.apolloClient$.pipe(
    switchMap(client => 
      client.mutate({
        mutation: DELETE_TEST_SPECIFICATION_MUTATION,
        variables: {Uid: uid}
      })
    ),
    map(result =>(result.data))
  )
}
}
