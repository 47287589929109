import { gql } from "apollo-angular";

export const GETALL_TEST_COVERAGE_QUERY = gql`query configuratorAllTestCoverages($RegBy: String) {
    configuratorAllTestCoverages(RegBy: $RegBy) {
        Version
        Uid
        ItemNo
        Name
        ModifiedBy
        ModificationTime
        LockedTime
        LockedBy
        CreationTime
        CreatedBy
        Comment
        ApprovedForTestLevel
        ApprovalStatus
    }
  }
  `
  
export const GET_TEST_OVERAGE_QUERY = gql`query configuratorTestCoverageById($Uid: String!) {
    configuratorTestCoverageById(Uid: $Uid) {
        ResponseMessages
        ReturnValue
        Success
    }
  }
  `

export const GET_TEST_OVERAGE_UI_TEMPLATE_QUERY = gql`query configuratorTestCoverageUiTemplate {
    configuratorTestCoverageUiTemplate {
        Name
        Category
        DefaultValue
        FieldType
        IsMultiValue
        HasHierarchyValues
        IsReadOnly
        Label
        IsRequired
        Order
        Tooltip
        ValueList
    }
  }
  `